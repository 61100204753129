define("incubex/utils/common", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sortTradableInstruments = _exports.matcher = _exports.numberWithCommas = void 0;

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  _exports.numberWithCommas = numberWithCommas;

  const matcher = (product, term) => {
    let words = term.split(' ');
    words = words.filter(item => item);
    let found = 1;
    let searchIndex = 0;
    words.forEach(x => {
      searchIndex = product.label.toLowerCase().indexOf(x.toLowerCase(), searchIndex);

      if (searchIndex < 0) {
        found = -1;
        return false;
      }
    });
    return found;
  };

  _exports.matcher = matcher;

  const sortTradableInstruments = (tradable_instruments, tradable_instrument_ids) => {
    let instruments = [];

    if (tradable_instrument_ids) {
      let ids = tradable_instrument_ids.split(',');
      ids.forEach(id => {
        let instrument = tradable_instruments.find(ins => ins.id == id);
        instruments.push(instrument);
      });
    } else {
      instruments = tradable_instruments || [];
    }

    return instruments;
  };

  _exports.sortTradableInstruments = sortTradableInstruments;
});