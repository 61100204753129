define("incubex/grpc_web/api/admin/v1/risk_admin_api_grpc_web_pb", [], function () {
  "use strict";

  /**
   * @fileoverview gRPC-Web generated client stub for admin.v1
   * @enhanceable
   * @public
   */
  // GENERATED CODE -- DO NOT EDIT!

  /* eslint-disable */
  // @ts-nocheck
  const grpc = {};
  grpc.web = require("grpc-web");

  var api_admin_v1_admin_pb = require("incubex/grpc_web/api/admin/v1/admin_pb.js");

  const proto = {};
  proto.admin = {};
  proto.admin.v1 = require("incubex/grpc_web/api/admin/v1/risk_admin_api_pb.js");
  /**
   * @param {string} hostname
   * @param {?Object} credentials
   * @param {?Object} options
   * @constructor
   * @struct
   * @final
   */

  proto.admin.v1.RiskAdminAPIClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options['format'] = 'text';
    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */

    this.client_ = new grpc.web.GrpcWebClientBase(options);
    /**
     * @private @const {string} The hostname
     */

    this.hostname_ = hostname;
  };
  /**
   * @param {string} hostname
   * @param {?Object} credentials
   * @param {?Object} options
   * @constructor
   * @struct
   * @final
   */


  proto.admin.v1.RiskAdminAPIPromiseClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options['format'] = 'text';
    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */

    this.client_ = new grpc.web.GrpcWebClientBase(options);
    /**
     * @private @const {string} The hostname
     */

    this.hostname_ = hostname;
  };
  /**
   * @const
   * @type {!grpc.web.MethodDescriptor<
   *   !proto.admin.v1.GetAccountRiskPositionsRequest,
   *   !proto.admin.v1.GetAccountRiskPositionsResponse>}
   */


  const methodDescriptor_RiskAdminAPI_GetAccountRiskPositions = new grpc.web.MethodDescriptor('/admin.v1.RiskAdminAPI/GetAccountRiskPositions', grpc.web.MethodType.UNARY, proto.admin.v1.GetAccountRiskPositionsRequest, proto.admin.v1.GetAccountRiskPositionsResponse,
  /**
   * @param {!proto.admin.v1.GetAccountRiskPositionsRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.admin.v1.GetAccountRiskPositionsResponse.deserializeBinary);
  /**
   * @const
   * @type {!grpc.web.AbstractClientBase.MethodInfo<
   *   !proto.admin.v1.GetAccountRiskPositionsRequest,
   *   !proto.admin.v1.GetAccountRiskPositionsResponse>}
   */

  const methodInfo_RiskAdminAPI_GetAccountRiskPositions = new grpc.web.AbstractClientBase.MethodInfo(proto.admin.v1.GetAccountRiskPositionsResponse,
  /**
   * @param {!proto.admin.v1.GetAccountRiskPositionsRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.admin.v1.GetAccountRiskPositionsResponse.deserializeBinary);
  /**
   * @param {!proto.admin.v1.GetAccountRiskPositionsRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @param {function(?grpc.web.Error, ?proto.admin.v1.GetAccountRiskPositionsResponse)}
   *     callback The callback function(error, response)
   * @return {!grpc.web.ClientReadableStream<!proto.admin.v1.GetAccountRiskPositionsResponse>|undefined}
   *     The XHR Node Readable Stream
   */

  proto.admin.v1.RiskAdminAPIClient.prototype.getAccountRiskPositions = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + '/admin.v1.RiskAdminAPI/GetAccountRiskPositions', request, metadata || {}, methodDescriptor_RiskAdminAPI_GetAccountRiskPositions, callback);
  };
  /**
   * @param {!proto.admin.v1.GetAccountRiskPositionsRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @return {!Promise<!proto.admin.v1.GetAccountRiskPositionsResponse>}
   *     Promise that resolves to the response
   */


  proto.admin.v1.RiskAdminAPIPromiseClient.prototype.getAccountRiskPositions = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + '/admin.v1.RiskAdminAPI/GetAccountRiskPositions', request, metadata || {}, methodDescriptor_RiskAdminAPI_GetAccountRiskPositions);
  };
  /**
   * @const
   * @type {!grpc.web.MethodDescriptor<
   *   !proto.admin.v1.GetRiskLimitRequest,
   *   !proto.admin.v1.GetRiskLimitResponse>}
   */


  const methodDescriptor_RiskAdminAPI_GetRiskLimit = new grpc.web.MethodDescriptor('/admin.v1.RiskAdminAPI/GetRiskLimit', grpc.web.MethodType.UNARY, proto.admin.v1.GetRiskLimitRequest, proto.admin.v1.GetRiskLimitResponse,
  /**
   * @param {!proto.admin.v1.GetRiskLimitRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.admin.v1.GetRiskLimitResponse.deserializeBinary);
  /**
   * @const
   * @type {!grpc.web.AbstractClientBase.MethodInfo<
   *   !proto.admin.v1.GetRiskLimitRequest,
   *   !proto.admin.v1.GetRiskLimitResponse>}
   */

  const methodInfo_RiskAdminAPI_GetRiskLimit = new grpc.web.AbstractClientBase.MethodInfo(proto.admin.v1.GetRiskLimitResponse,
  /**
   * @param {!proto.admin.v1.GetRiskLimitRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.admin.v1.GetRiskLimitResponse.deserializeBinary);
  /**
   * @param {!proto.admin.v1.GetRiskLimitRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @param {function(?grpc.web.Error, ?proto.admin.v1.GetRiskLimitResponse)}
   *     callback The callback function(error, response)
   * @return {!grpc.web.ClientReadableStream<!proto.admin.v1.GetRiskLimitResponse>|undefined}
   *     The XHR Node Readable Stream
   */

  proto.admin.v1.RiskAdminAPIClient.prototype.getRiskLimit = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + '/admin.v1.RiskAdminAPI/GetRiskLimit', request, metadata || {}, methodDescriptor_RiskAdminAPI_GetRiskLimit, callback);
  };
  /**
   * @param {!proto.admin.v1.GetRiskLimitRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @return {!Promise<!proto.admin.v1.GetRiskLimitResponse>}
   *     Promise that resolves to the response
   */


  proto.admin.v1.RiskAdminAPIPromiseClient.prototype.getRiskLimit = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + '/admin.v1.RiskAdminAPI/GetRiskLimit', request, metadata || {}, methodDescriptor_RiskAdminAPI_GetRiskLimit);
  };
  /**
   * @const
   * @type {!grpc.web.MethodDescriptor<
   *   !proto.admin.v1.SetRiskLimitRequest,
   *   !proto.admin.v1.SetRiskLimitResponse>}
   */


  const methodDescriptor_RiskAdminAPI_SetRiskLimit = new grpc.web.MethodDescriptor('/admin.v1.RiskAdminAPI/SetRiskLimit', grpc.web.MethodType.UNARY, proto.admin.v1.SetRiskLimitRequest, proto.admin.v1.SetRiskLimitResponse,
  /**
   * @param {!proto.admin.v1.SetRiskLimitRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.admin.v1.SetRiskLimitResponse.deserializeBinary);
  /**
   * @const
   * @type {!grpc.web.AbstractClientBase.MethodInfo<
   *   !proto.admin.v1.SetRiskLimitRequest,
   *   !proto.admin.v1.SetRiskLimitResponse>}
   */

  const methodInfo_RiskAdminAPI_SetRiskLimit = new grpc.web.AbstractClientBase.MethodInfo(proto.admin.v1.SetRiskLimitResponse,
  /**
   * @param {!proto.admin.v1.SetRiskLimitRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.admin.v1.SetRiskLimitResponse.deserializeBinary);
  /**
   * @param {!proto.admin.v1.SetRiskLimitRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @param {function(?grpc.web.Error, ?proto.admin.v1.SetRiskLimitResponse)}
   *     callback The callback function(error, response)
   * @return {!grpc.web.ClientReadableStream<!proto.admin.v1.SetRiskLimitResponse>|undefined}
   *     The XHR Node Readable Stream
   */

  proto.admin.v1.RiskAdminAPIClient.prototype.setRiskLimit = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + '/admin.v1.RiskAdminAPI/SetRiskLimit', request, metadata || {}, methodDescriptor_RiskAdminAPI_SetRiskLimit, callback);
  };
  /**
   * @param {!proto.admin.v1.SetRiskLimitRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @return {!Promise<!proto.admin.v1.SetRiskLimitResponse>}
   *     Promise that resolves to the response
   */


  proto.admin.v1.RiskAdminAPIPromiseClient.prototype.setRiskLimit = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + '/admin.v1.RiskAdminAPI/SetRiskLimit', request, metadata || {}, methodDescriptor_RiskAdminAPI_SetRiskLimit);
  };

  module.exports = proto.admin.v1;
});