define("incubex/services/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NotificationService extends Ember.Service {
    constructor() {
      super(...arguments);
      this.messages = [];
      this.toasts = [];
      this.delay = {
        auto: 2500,
        close: 250
      };
    }

    addMessage(text, type, options = {}) {
      let message = {
        text,
        type,
        options
      };
      this.messages.insertAt(0, message);
    }

    removeMessage(message) {
      this.messages.removeObject(message);
    }

    addToast(text, options = {}) {
      let toast = {
        text,
        options
      };
      this.toasts.pushObject(toast);
    }

    removeToast(toast) {
      this.toasts.removeObject(toast);
    }

    clearAllMessages() {
      this.messages.clear();
    }

    clearErrors() {
      let errors = this.messages.filterBy('type', 'error');
      this.messages.removeObjects(errors);
    }

    error(error, options = {
      countdown: false,
      closeHandler: null,
      context: null
    }) {
      error = error.message || error;
      this.addMessage(error, 'error', options);
    }

    success(text, options = {
      countdown: true,
      closeHandler: null,
      context: null
    }) {
      this.addMessage(text, 'success', options);
    }

    info(text, options = {
      countdown: true,
      closeHandler: null,
      context: null
    }) {
      this.addMessage(text, 'info', options);
    }

    toast(text, options = {
      countdown: false,
      closeHandler: null,
      context: null
    }) {
      this.addToast(text, options);
    }

  }

  _exports.default = NotificationService;
});