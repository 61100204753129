define("incubex/routes/authenticated/index", ["exports", "incubex/types/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AuthenticatedIndexRoute = (_class = (_temp = class AuthenticatedIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sessionApi", _descriptor, this);

      _initializerDefineProperty(this, "portfolioTabsService", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);
    }

    async model() {
      let tabs = await this.sessionApi.get(_url.default.PORTFOLIO_TABS);
      let time_and_sales_tabs = await this.sessionApi.get(_url.default.TIME_AND_SALES_TABS);
      return {
        tabs,
        time_and_sales_tabs
      };
    }

    setupController(controller, model) {
      if (model.tabs.length > 0) {
        let selectedPortfolioId = this.portfolioTabsService.getSelectedPortfolioIdFromLocalStorage();

        if (selectedPortfolioId && model.tabs.find(tab => tab.id == selectedPortfolioId)) {
          controller.selectedPortfolioId = parseInt(selectedPortfolioId);
        } else {
          controller.selectedPortfolioId = model.tabs[0].id;
        }
      }

      if (model.time_and_sales_tabs.length > 0) {
        let selectedTnSId = this.portfolioTabsService.getSelectedTnSIdFromLocalStorage();

        if (selectedTnSId && model.time_and_sales_tabs.find(tab => tab.id == selectedTnSId)) {
          controller.selectedTnsId = parseInt(selectedTnSId);
        } else controller.selectedTnsId = model.time_and_sales_tabs[0].id;
      }

      Ember.run.later(function () {
        controller.fetchAndLoadParkedOrders();
        controller.connectToMarketDataSocket();
        controller.connectToBlockTradeSocket();
        controller.connectToOrdersDataSocket();
        controller.connectTosystemNotificationsSocket();
        controller.getProducts();
      }, 0);
      controller.model = model;
      controller.tabs = model.tabs;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "portfolioTabsService", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = AuthenticatedIndexRoute;
});