define("incubex/grpc_web/api/webapigateway/admin/v1/trading_info_api_pb", [], function () {
  "use strict";

  /* eslint-disable */
  // source: api/webapigateway/admin/v1/trading_info_api.proto

  /**
   * @fileoverview
   * @enhanceable
   * @suppress {messageConventions} JS Compiler reports an error if a variable or
   *     field starts with 'MSG_' and isn't a translatable message.
   * @public
   */
  // GENERATED CODE -- DO NOT EDIT!
  var jspb = require("google-protobuf");

  var goog = jspb;
  var global = Function('return this')();
  goog.exportSymbol('proto.webapigateway.admin.v1.CreateOrderExecutionResponse', null, global);
  goog.exportSymbol('proto.webapigateway.admin.v1.OrderExecution', null, global);
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */

  proto.webapigateway.admin.v1.OrderExecution = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.webapigateway.admin.v1.OrderExecution, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.webapigateway.admin.v1.OrderExecution.displayName = 'proto.webapigateway.admin.v1.OrderExecution';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.webapigateway.admin.v1.CreateOrderExecutionResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.webapigateway.admin.v1.CreateOrderExecutionResponse, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.webapigateway.admin.v1.CreateOrderExecutionResponse.displayName = 'proto.webapigateway.admin.v1.CreateOrderExecutionResponse';
  }

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.webapigateway.admin.v1.OrderExecution.prototype.toObject = function (opt_includeInstance) {
      return proto.webapigateway.admin.v1.OrderExecution.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.webapigateway.admin.v1.OrderExecution} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.webapigateway.admin.v1.OrderExecution.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        t7BusinessUnit: jspb.Message.getFieldWithDefault(msg, 1, ""),
        t7UserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
        orderId: jspb.Message.getFieldWithDefault(msg, 3, ""),
        clordId: jspb.Message.getFieldWithDefault(msg, 4, ""),
        origClordId: jspb.Message.getFieldWithDefault(msg, 5, ""),
        execId: jspb.Message.getFieldWithDefault(msg, 6, ""),
        execType: jspb.Message.getFieldWithDefault(msg, 7, ""),
        execRestatementReason: jspb.Message.getFieldWithDefault(msg, 8, ""),
        marketSegmentId: jspb.Message.getFieldWithDefault(msg, 9, ""),
        securityId: jspb.Message.getFieldWithDefault(msg, 10, ""),
        orderQty: jspb.Message.getFieldWithDefault(msg, 11, ""),
        cumQty: jspb.Message.getFieldWithDefault(msg, 12, ""),
        leavesQty: jspb.Message.getFieldWithDefault(msg, 13, ""),
        cxlQty: jspb.Message.getFieldWithDefault(msg, 14, ""),
        ordType: jspb.Message.getFieldWithDefault(msg, 15, ""),
        price: jspb.Message.getFieldWithDefault(msg, 16, ""),
        side: jspb.Message.getFieldWithDefault(msg, 17, ""),
        timeInForce: jspb.Message.getFieldWithDefault(msg, 18, ""),
        ordStatus: jspb.Message.getFieldWithDefault(msg, 19, ""),
        trdRegTsEntryTime: jspb.Message.getFieldWithDefault(msg, 20, ""),
        trdRegTsTimePriority: jspb.Message.getFieldWithDefault(msg, 21, ""),
        expirationDate: jspb.Message.getFieldWithDefault(msg, 22, ""),
        positionEffect: jspb.Message.getFieldWithDefault(msg, 23, ""),
        clearingAccount: jspb.Message.getFieldWithDefault(msg, 24, ""),
        fillPx: jspb.Message.getFieldWithDefault(msg, 25, ""),
        fillQty: jspb.Message.getFieldWithDefault(msg, 26, ""),
        fillMatchId: jspb.Message.getFieldWithDefault(msg, 27, ""),
        fillExecId: jspb.Message.getFieldWithDefault(msg, 28, ""),
        fillLiquidityInd: jspb.Message.getFieldWithDefault(msg, 29, ""),
        leg1FillRefId: jspb.Message.getFieldWithDefault(msg, 30, ""),
        leg1SecurityId: jspb.Message.getFieldWithDefault(msg, 31, ""),
        leg1LastPx: jspb.Message.getFieldWithDefault(msg, 32, ""),
        leg1LastQty: jspb.Message.getFieldWithDefault(msg, 33, ""),
        leg1ExecId: jspb.Message.getFieldWithDefault(msg, 34, ""),
        leg1Side: jspb.Message.getFieldWithDefault(msg, 35, ""),
        leg2FillRefId: jspb.Message.getFieldWithDefault(msg, 36, ""),
        leg2SecurityId: jspb.Message.getFieldWithDefault(msg, 37, ""),
        leg2LastPx: jspb.Message.getFieldWithDefault(msg, 38, ""),
        leg2LastQty: jspb.Message.getFieldWithDefault(msg, 39, ""),
        leg2ExecId: jspb.Message.getFieldWithDefault(msg, 40, ""),
        leg2Side: jspb.Message.getFieldWithDefault(msg, 41, ""),
        productComplex: jspb.Message.getFieldWithDefault(msg, 42, ""),
        rejectionCode: jspb.Message.getFieldWithDefault(msg, 43, ""),
        rejectionText: jspb.Message.getFieldWithDefault(msg, 44, ""),
        rejectionRequestType: jspb.Message.getFieldWithDefault(msg, 45, ""),
        userEmail: jspb.Message.getFieldWithDefault(msg, 46, ""),
        requestTimestamp: jspb.Message.getFieldWithDefault(msg, 47, "")
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.webapigateway.admin.v1.OrderExecution}
   */


  proto.webapigateway.admin.v1.OrderExecution.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.webapigateway.admin.v1.OrderExecution();
    return proto.webapigateway.admin.v1.OrderExecution.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.webapigateway.admin.v1.OrderExecution} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.webapigateway.admin.v1.OrderExecution}
   */


  proto.webapigateway.admin.v1.OrderExecution.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setT7BusinessUnit(value);
          break;

        case 2:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setT7UserId(value);
          break;

        case 3:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setOrderId(value);
          break;

        case 4:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setClordId(value);
          break;

        case 5:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setOrigClordId(value);
          break;

        case 6:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setExecId(value);
          break;

        case 7:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setExecType(value);
          break;

        case 8:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setExecRestatementReason(value);
          break;

        case 9:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setMarketSegmentId(value);
          break;

        case 10:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setSecurityId(value);
          break;

        case 11:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setOrderQty(value);
          break;

        case 12:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setCumQty(value);
          break;

        case 13:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLeavesQty(value);
          break;

        case 14:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setCxlQty(value);
          break;

        case 15:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setOrdType(value);
          break;

        case 16:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setPrice(value);
          break;

        case 17:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setSide(value);
          break;

        case 18:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setTimeInForce(value);
          break;

        case 19:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setOrdStatus(value);
          break;

        case 20:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setTrdRegTsEntryTime(value);
          break;

        case 21:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setTrdRegTsTimePriority(value);
          break;

        case 22:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setExpirationDate(value);
          break;

        case 23:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setPositionEffect(value);
          break;

        case 24:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setClearingAccount(value);
          break;

        case 25:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setFillPx(value);
          break;

        case 26:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setFillQty(value);
          break;

        case 27:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setFillMatchId(value);
          break;

        case 28:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setFillExecId(value);
          break;

        case 29:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setFillLiquidityInd(value);
          break;

        case 30:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLeg1FillRefId(value);
          break;

        case 31:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLeg1SecurityId(value);
          break;

        case 32:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLeg1LastPx(value);
          break;

        case 33:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLeg1LastQty(value);
          break;

        case 34:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLeg1ExecId(value);
          break;

        case 35:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLeg1Side(value);
          break;

        case 36:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLeg2FillRefId(value);
          break;

        case 37:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLeg2SecurityId(value);
          break;

        case 38:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLeg2LastPx(value);
          break;

        case 39:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLeg2LastQty(value);
          break;

        case 40:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLeg2ExecId(value);
          break;

        case 41:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLeg2Side(value);
          break;

        case 42:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setProductComplex(value);
          break;

        case 43:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setRejectionCode(value);
          break;

        case 44:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setRejectionText(value);
          break;

        case 45:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setRejectionRequestType(value);
          break;

        case 46:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setUserEmail(value);
          break;

        case 47:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setRequestTimestamp(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.webapigateway.admin.v1.OrderExecution.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.webapigateway.admin.v1.OrderExecution} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.webapigateway.admin.v1.OrderExecution.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getT7BusinessUnit();

    if (f.length > 0) {
      writer.writeString(1, f);
    }

    f = message.getT7UserId();

    if (f.length > 0) {
      writer.writeString(2, f);
    }

    f = message.getOrderId();

    if (f.length > 0) {
      writer.writeString(3, f);
    }

    f = message.getClordId();

    if (f.length > 0) {
      writer.writeString(4, f);
    }

    f = message.getOrigClordId();

    if (f.length > 0) {
      writer.writeString(5, f);
    }

    f = message.getExecId();

    if (f.length > 0) {
      writer.writeString(6, f);
    }

    f = message.getExecType();

    if (f.length > 0) {
      writer.writeString(7, f);
    }

    f = message.getExecRestatementReason();

    if (f.length > 0) {
      writer.writeString(8, f);
    }

    f = message.getMarketSegmentId();

    if (f.length > 0) {
      writer.writeString(9, f);
    }

    f = message.getSecurityId();

    if (f.length > 0) {
      writer.writeString(10, f);
    }

    f = message.getOrderQty();

    if (f.length > 0) {
      writer.writeString(11, f);
    }

    f = message.getCumQty();

    if (f.length > 0) {
      writer.writeString(12, f);
    }

    f = message.getLeavesQty();

    if (f.length > 0) {
      writer.writeString(13, f);
    }

    f = message.getCxlQty();

    if (f.length > 0) {
      writer.writeString(14, f);
    }

    f = message.getOrdType();

    if (f.length > 0) {
      writer.writeString(15, f);
    }

    f = message.getPrice();

    if (f.length > 0) {
      writer.writeString(16, f);
    }

    f = message.getSide();

    if (f.length > 0) {
      writer.writeString(17, f);
    }

    f = message.getTimeInForce();

    if (f.length > 0) {
      writer.writeString(18, f);
    }

    f = message.getOrdStatus();

    if (f.length > 0) {
      writer.writeString(19, f);
    }

    f = message.getTrdRegTsEntryTime();

    if (f.length > 0) {
      writer.writeString(20, f);
    }

    f = message.getTrdRegTsTimePriority();

    if (f.length > 0) {
      writer.writeString(21, f);
    }

    f = message.getExpirationDate();

    if (f.length > 0) {
      writer.writeString(22, f);
    }

    f = message.getPositionEffect();

    if (f.length > 0) {
      writer.writeString(23, f);
    }

    f = message.getClearingAccount();

    if (f.length > 0) {
      writer.writeString(24, f);
    }

    f = message.getFillPx();

    if (f.length > 0) {
      writer.writeString(25, f);
    }

    f = message.getFillQty();

    if (f.length > 0) {
      writer.writeString(26, f);
    }

    f = message.getFillMatchId();

    if (f.length > 0) {
      writer.writeString(27, f);
    }

    f = message.getFillExecId();

    if (f.length > 0) {
      writer.writeString(28, f);
    }

    f = message.getFillLiquidityInd();

    if (f.length > 0) {
      writer.writeString(29, f);
    }

    f = message.getLeg1FillRefId();

    if (f.length > 0) {
      writer.writeString(30, f);
    }

    f = message.getLeg1SecurityId();

    if (f.length > 0) {
      writer.writeString(31, f);
    }

    f = message.getLeg1LastPx();

    if (f.length > 0) {
      writer.writeString(32, f);
    }

    f = message.getLeg1LastQty();

    if (f.length > 0) {
      writer.writeString(33, f);
    }

    f = message.getLeg1ExecId();

    if (f.length > 0) {
      writer.writeString(34, f);
    }

    f = message.getLeg1Side();

    if (f.length > 0) {
      writer.writeString(35, f);
    }

    f = message.getLeg2FillRefId();

    if (f.length > 0) {
      writer.writeString(36, f);
    }

    f = message.getLeg2SecurityId();

    if (f.length > 0) {
      writer.writeString(37, f);
    }

    f = message.getLeg2LastPx();

    if (f.length > 0) {
      writer.writeString(38, f);
    }

    f = message.getLeg2LastQty();

    if (f.length > 0) {
      writer.writeString(39, f);
    }

    f = message.getLeg2ExecId();

    if (f.length > 0) {
      writer.writeString(40, f);
    }

    f = message.getLeg2Side();

    if (f.length > 0) {
      writer.writeString(41, f);
    }

    f = message.getProductComplex();

    if (f.length > 0) {
      writer.writeString(42, f);
    }

    f = message.getRejectionCode();

    if (f.length > 0) {
      writer.writeString(43, f);
    }

    f = message.getRejectionText();

    if (f.length > 0) {
      writer.writeString(44, f);
    }

    f = message.getRejectionRequestType();

    if (f.length > 0) {
      writer.writeString(45, f);
    }

    f = message.getUserEmail();

    if (f.length > 0) {
      writer.writeString(46, f);
    }

    f = message.getRequestTimestamp();

    if (f.length > 0) {
      writer.writeString(47, f);
    }
  };
  /**
   * optional string t7_business_unit = 1;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getT7BusinessUnit = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 1, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setT7BusinessUnit = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };
  /**
   * optional string t7_user_id = 2;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getT7UserId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 2, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setT7UserId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };
  /**
   * optional string order_id = 3;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getOrderId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 3, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setOrderId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
  };
  /**
   * optional string clord_id = 4;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getClordId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 4, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setClordId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
  };
  /**
   * optional string orig_clord_id = 5;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getOrigClordId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 5, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setOrigClordId = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
  };
  /**
   * optional string exec_id = 6;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getExecId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 6, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setExecId = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
  };
  /**
   * optional string exec_type = 7;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getExecType = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 7, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setExecType = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
  };
  /**
   * optional string exec_restatement_reason = 8;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getExecRestatementReason = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 8, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setExecRestatementReason = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
  };
  /**
   * optional string market_segment_id = 9;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getMarketSegmentId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 9, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setMarketSegmentId = function (value) {
    return jspb.Message.setProto3StringField(this, 9, value);
  };
  /**
   * optional string security_id = 10;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getSecurityId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 10, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setSecurityId = function (value) {
    return jspb.Message.setProto3StringField(this, 10, value);
  };
  /**
   * optional string order_qty = 11;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getOrderQty = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 11, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setOrderQty = function (value) {
    return jspb.Message.setProto3StringField(this, 11, value);
  };
  /**
   * optional string cum_qty = 12;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getCumQty = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 12, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setCumQty = function (value) {
    return jspb.Message.setProto3StringField(this, 12, value);
  };
  /**
   * optional string leaves_qty = 13;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getLeavesQty = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 13, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setLeavesQty = function (value) {
    return jspb.Message.setProto3StringField(this, 13, value);
  };
  /**
   * optional string cxl_qty = 14;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getCxlQty = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 14, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setCxlQty = function (value) {
    return jspb.Message.setProto3StringField(this, 14, value);
  };
  /**
   * optional string ord_type = 15;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getOrdType = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 15, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setOrdType = function (value) {
    return jspb.Message.setProto3StringField(this, 15, value);
  };
  /**
   * optional string price = 16;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getPrice = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 16, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setPrice = function (value) {
    return jspb.Message.setProto3StringField(this, 16, value);
  };
  /**
   * optional string side = 17;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getSide = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 17, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setSide = function (value) {
    return jspb.Message.setProto3StringField(this, 17, value);
  };
  /**
   * optional string time_in_force = 18;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getTimeInForce = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 18, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setTimeInForce = function (value) {
    return jspb.Message.setProto3StringField(this, 18, value);
  };
  /**
   * optional string ord_status = 19;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getOrdStatus = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 19, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setOrdStatus = function (value) {
    return jspb.Message.setProto3StringField(this, 19, value);
  };
  /**
   * optional string trd_reg_ts_entry_time = 20;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getTrdRegTsEntryTime = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 20, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setTrdRegTsEntryTime = function (value) {
    return jspb.Message.setProto3StringField(this, 20, value);
  };
  /**
   * optional string trd_reg_ts_time_priority = 21;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getTrdRegTsTimePriority = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 21, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setTrdRegTsTimePriority = function (value) {
    return jspb.Message.setProto3StringField(this, 21, value);
  };
  /**
   * optional string expiration_date = 22;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getExpirationDate = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 22, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setExpirationDate = function (value) {
    return jspb.Message.setProto3StringField(this, 22, value);
  };
  /**
   * optional string position_effect = 23;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getPositionEffect = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 23, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setPositionEffect = function (value) {
    return jspb.Message.setProto3StringField(this, 23, value);
  };
  /**
   * optional string clearing_account = 24;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getClearingAccount = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 24, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setClearingAccount = function (value) {
    return jspb.Message.setProto3StringField(this, 24, value);
  };
  /**
   * optional string fill_px = 25;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getFillPx = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 25, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setFillPx = function (value) {
    return jspb.Message.setProto3StringField(this, 25, value);
  };
  /**
   * optional string fill_qty = 26;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getFillQty = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 26, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setFillQty = function (value) {
    return jspb.Message.setProto3StringField(this, 26, value);
  };
  /**
   * optional string fill_match_id = 27;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getFillMatchId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 27, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setFillMatchId = function (value) {
    return jspb.Message.setProto3StringField(this, 27, value);
  };
  /**
   * optional string fill_exec_id = 28;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getFillExecId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 28, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setFillExecId = function (value) {
    return jspb.Message.setProto3StringField(this, 28, value);
  };
  /**
   * optional string fill_liquidity_ind = 29;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getFillLiquidityInd = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 29, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setFillLiquidityInd = function (value) {
    return jspb.Message.setProto3StringField(this, 29, value);
  };
  /**
   * optional string leg1_fill_ref_id = 30;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getLeg1FillRefId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 30, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setLeg1FillRefId = function (value) {
    return jspb.Message.setProto3StringField(this, 30, value);
  };
  /**
   * optional string leg1_security_id = 31;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getLeg1SecurityId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 31, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setLeg1SecurityId = function (value) {
    return jspb.Message.setProto3StringField(this, 31, value);
  };
  /**
   * optional string leg1_last_px = 32;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getLeg1LastPx = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 32, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setLeg1LastPx = function (value) {
    return jspb.Message.setProto3StringField(this, 32, value);
  };
  /**
   * optional string leg1_last_qty = 33;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getLeg1LastQty = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 33, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setLeg1LastQty = function (value) {
    return jspb.Message.setProto3StringField(this, 33, value);
  };
  /**
   * optional string leg1_exec_id = 34;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getLeg1ExecId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 34, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setLeg1ExecId = function (value) {
    return jspb.Message.setProto3StringField(this, 34, value);
  };
  /**
   * optional string leg1_side = 35;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getLeg1Side = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 35, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setLeg1Side = function (value) {
    return jspb.Message.setProto3StringField(this, 35, value);
  };
  /**
   * optional string leg2_fill_ref_id = 36;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getLeg2FillRefId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 36, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setLeg2FillRefId = function (value) {
    return jspb.Message.setProto3StringField(this, 36, value);
  };
  /**
   * optional string leg2_security_id = 37;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getLeg2SecurityId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 37, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setLeg2SecurityId = function (value) {
    return jspb.Message.setProto3StringField(this, 37, value);
  };
  /**
   * optional string leg2_last_px = 38;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getLeg2LastPx = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 38, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setLeg2LastPx = function (value) {
    return jspb.Message.setProto3StringField(this, 38, value);
  };
  /**
   * optional string leg2_last_qty = 39;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getLeg2LastQty = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 39, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setLeg2LastQty = function (value) {
    return jspb.Message.setProto3StringField(this, 39, value);
  };
  /**
   * optional string leg2_exec_id = 40;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getLeg2ExecId = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 40, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setLeg2ExecId = function (value) {
    return jspb.Message.setProto3StringField(this, 40, value);
  };
  /**
   * optional string leg2_side = 41;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getLeg2Side = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 41, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setLeg2Side = function (value) {
    return jspb.Message.setProto3StringField(this, 41, value);
  };
  /**
   * optional string product_complex = 42;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getProductComplex = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 42, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setProductComplex = function (value) {
    return jspb.Message.setProto3StringField(this, 42, value);
  };
  /**
   * optional string rejection_code = 43;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getRejectionCode = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 43, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setRejectionCode = function (value) {
    return jspb.Message.setProto3StringField(this, 43, value);
  };
  /**
   * optional string rejection_text = 44;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getRejectionText = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 44, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setRejectionText = function (value) {
    return jspb.Message.setProto3StringField(this, 44, value);
  };
  /**
   * optional string rejection_request_type = 45;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getRejectionRequestType = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 45, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setRejectionRequestType = function (value) {
    return jspb.Message.setProto3StringField(this, 45, value);
  };
  /**
   * optional string user_email = 46;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getUserEmail = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 46, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setUserEmail = function (value) {
    return jspb.Message.setProto3StringField(this, 46, value);
  };
  /**
   * optional string request_timestamp = 47;
   * @return {string}
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.getRequestTimestamp = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 47, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.webapigateway.admin.v1.OrderExecution} returns this
   */


  proto.webapigateway.admin.v1.OrderExecution.prototype.setRequestTimestamp = function (value) {
    return jspb.Message.setProto3StringField(this, 47, value);
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.webapigateway.admin.v1.CreateOrderExecutionResponse.prototype.toObject = function (opt_includeInstance) {
      return proto.webapigateway.admin.v1.CreateOrderExecutionResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.webapigateway.admin.v1.CreateOrderExecutionResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.webapigateway.admin.v1.CreateOrderExecutionResponse.toObject = function (includeInstance, msg) {
      var f,
          obj = {};

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.webapigateway.admin.v1.CreateOrderExecutionResponse}
   */


  proto.webapigateway.admin.v1.CreateOrderExecutionResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.webapigateway.admin.v1.CreateOrderExecutionResponse();
    return proto.webapigateway.admin.v1.CreateOrderExecutionResponse.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.webapigateway.admin.v1.CreateOrderExecutionResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.webapigateway.admin.v1.CreateOrderExecutionResponse}
   */


  proto.webapigateway.admin.v1.CreateOrderExecutionResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.webapigateway.admin.v1.CreateOrderExecutionResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.webapigateway.admin.v1.CreateOrderExecutionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.webapigateway.admin.v1.CreateOrderExecutionResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.webapigateway.admin.v1.CreateOrderExecutionResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
  };

  goog.object.extend(exports, proto.webapigateway.admin.v1);
});