define("incubex/grpc_web/api/admin/v1/account_admin_api_grpc_web_pb", [], function () {
  "use strict";

  /**
   * @fileoverview gRPC-Web generated client stub for admin.v1
   * @enhanceable
   * @public
   */
  // GENERATED CODE -- DO NOT EDIT!

  /* eslint-disable */
  // @ts-nocheck
  const grpc = {};
  grpc.web = require("grpc-web");

  var api_admin_v1_admin_pb = require("incubex/grpc_web/api/admin/v1/admin_pb.js");

  const proto = {};
  proto.admin = {};
  proto.admin.v1 = require("incubex/grpc_web/api/admin/v1/account_admin_api_pb.js");
  /**
   * @param {string} hostname
   * @param {?Object} credentials
   * @param {?Object} options
   * @constructor
   * @struct
   * @final
   */

  proto.admin.v1.AccountAdminAPIClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options['format'] = 'text';
    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */

    this.client_ = new grpc.web.GrpcWebClientBase(options);
    /**
     * @private @const {string} The hostname
     */

    this.hostname_ = hostname;
  };
  /**
   * @param {string} hostname
   * @param {?Object} credentials
   * @param {?Object} options
   * @constructor
   * @struct
   * @final
   */


  proto.admin.v1.AccountAdminAPIPromiseClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options['format'] = 'text';
    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */

    this.client_ = new grpc.web.GrpcWebClientBase(options);
    /**
     * @private @const {string} The hostname
     */

    this.hostname_ = hostname;
  };
  /**
   * @const
   * @type {!grpc.web.MethodDescriptor<
   *   !proto.admin.v1.GetAccountStateRequest,
   *   !proto.admin.v1.GetAccountStateResponse>}
   */


  const methodDescriptor_AccountAdminAPI_GetAccountState = new grpc.web.MethodDescriptor('/admin.v1.AccountAdminAPI/GetAccountState', grpc.web.MethodType.UNARY, proto.admin.v1.GetAccountStateRequest, proto.admin.v1.GetAccountStateResponse,
  /**
   * @param {!proto.admin.v1.GetAccountStateRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.admin.v1.GetAccountStateResponse.deserializeBinary);
  /**
   * @const
   * @type {!grpc.web.AbstractClientBase.MethodInfo<
   *   !proto.admin.v1.GetAccountStateRequest,
   *   !proto.admin.v1.GetAccountStateResponse>}
   */

  const methodInfo_AccountAdminAPI_GetAccountState = new grpc.web.AbstractClientBase.MethodInfo(proto.admin.v1.GetAccountStateResponse,
  /**
   * @param {!proto.admin.v1.GetAccountStateRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.admin.v1.GetAccountStateResponse.deserializeBinary);
  /**
   * @param {!proto.admin.v1.GetAccountStateRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @param {function(?grpc.web.Error, ?proto.admin.v1.GetAccountStateResponse)}
   *     callback The callback function(error, response)
   * @return {!grpc.web.ClientReadableStream<!proto.admin.v1.GetAccountStateResponse>|undefined}
   *     The XHR Node Readable Stream
   */

  proto.admin.v1.AccountAdminAPIClient.prototype.getAccountState = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + '/admin.v1.AccountAdminAPI/GetAccountState', request, metadata || {}, methodDescriptor_AccountAdminAPI_GetAccountState, callback);
  };
  /**
   * @param {!proto.admin.v1.GetAccountStateRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @return {!Promise<!proto.admin.v1.GetAccountStateResponse>}
   *     Promise that resolves to the response
   */


  proto.admin.v1.AccountAdminAPIPromiseClient.prototype.getAccountState = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + '/admin.v1.AccountAdminAPI/GetAccountState', request, metadata || {}, methodDescriptor_AccountAdminAPI_GetAccountState);
  };
  /**
   * @const
   * @type {!grpc.web.MethodDescriptor<
   *   !proto.admin.v1.SetAccountStateRequest,
   *   !proto.admin.v1.SetAccountStateResponse>}
   */


  const methodDescriptor_AccountAdminAPI_SetAccountState = new grpc.web.MethodDescriptor('/admin.v1.AccountAdminAPI/SetAccountState', grpc.web.MethodType.UNARY, proto.admin.v1.SetAccountStateRequest, proto.admin.v1.SetAccountStateResponse,
  /**
   * @param {!proto.admin.v1.SetAccountStateRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.admin.v1.SetAccountStateResponse.deserializeBinary);
  /**
   * @const
   * @type {!grpc.web.AbstractClientBase.MethodInfo<
   *   !proto.admin.v1.SetAccountStateRequest,
   *   !proto.admin.v1.SetAccountStateResponse>}
   */

  const methodInfo_AccountAdminAPI_SetAccountState = new grpc.web.AbstractClientBase.MethodInfo(proto.admin.v1.SetAccountStateResponse,
  /**
   * @param {!proto.admin.v1.SetAccountStateRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.admin.v1.SetAccountStateResponse.deserializeBinary);
  /**
   * @param {!proto.admin.v1.SetAccountStateRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @param {function(?grpc.web.Error, ?proto.admin.v1.SetAccountStateResponse)}
   *     callback The callback function(error, response)
   * @return {!grpc.web.ClientReadableStream<!proto.admin.v1.SetAccountStateResponse>|undefined}
   *     The XHR Node Readable Stream
   */

  proto.admin.v1.AccountAdminAPIClient.prototype.setAccountState = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + '/admin.v1.AccountAdminAPI/SetAccountState', request, metadata || {}, methodDescriptor_AccountAdminAPI_SetAccountState, callback);
  };
  /**
   * @param {!proto.admin.v1.SetAccountStateRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @return {!Promise<!proto.admin.v1.SetAccountStateResponse>}
   *     Promise that resolves to the response
   */


  proto.admin.v1.AccountAdminAPIPromiseClient.prototype.setAccountState = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + '/admin.v1.AccountAdminAPI/SetAccountState', request, metadata || {}, methodDescriptor_AccountAdminAPI_SetAccountState);
  };

  module.exports = proto.admin.v1;
});