define("incubex/grpc_web/api/webapigateway/admin/v1/trading_info_api_grpc_web_pb", [], function () {
  "use strict";

  /**
   * @fileoverview gRPC-Web generated client stub for webapigateway.admin.v1
   * @enhanceable
   * @public
   */
  // GENERATED CODE -- DO NOT EDIT!

  /* eslint-disable */
  // @ts-nocheck
  const grpc = {};
  grpc.web = require("grpc-web");
  const proto = {};
  proto.webapigateway = {};
  proto.webapigateway.admin = {};
  proto.webapigateway.admin.v1 = require("incubex/grpc_web/api/webapigateway/admin/v1/trading_info_api_pb.js");
  /**
   * @param {string} hostname
   * @param {?Object} credentials
   * @param {?Object} options
   * @constructor
   * @struct
   * @final
   */

  proto.webapigateway.admin.v1.TradingInfoAPIClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options['format'] = 'text';
    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */

    this.client_ = new grpc.web.GrpcWebClientBase(options);
    /**
     * @private @const {string} The hostname
     */

    this.hostname_ = hostname;
  };
  /**
   * @param {string} hostname
   * @param {?Object} credentials
   * @param {?Object} options
   * @constructor
   * @struct
   * @final
   */


  proto.webapigateway.admin.v1.TradingInfoAPIPromiseClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options['format'] = 'text';
    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */

    this.client_ = new grpc.web.GrpcWebClientBase(options);
    /**
     * @private @const {string} The hostname
     */

    this.hostname_ = hostname;
  };
  /**
   * @const
   * @type {!grpc.web.MethodDescriptor<
   *   !proto.webapigateway.admin.v1.OrderExecution,
   *   !proto.webapigateway.admin.v1.CreateOrderExecutionResponse>}
   */


  const methodDescriptor_TradingInfoAPI_CreateOrderExecution = new grpc.web.MethodDescriptor('/webapigateway.admin.v1.TradingInfoAPI/CreateOrderExecution', grpc.web.MethodType.UNARY, proto.webapigateway.admin.v1.OrderExecution, proto.webapigateway.admin.v1.CreateOrderExecutionResponse,
  /**
   * @param {!proto.webapigateway.admin.v1.OrderExecution} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.webapigateway.admin.v1.CreateOrderExecutionResponse.deserializeBinary);
  /**
   * @const
   * @type {!grpc.web.AbstractClientBase.MethodInfo<
   *   !proto.webapigateway.admin.v1.OrderExecution,
   *   !proto.webapigateway.admin.v1.CreateOrderExecutionResponse>}
   */

  const methodInfo_TradingInfoAPI_CreateOrderExecution = new grpc.web.AbstractClientBase.MethodInfo(proto.webapigateway.admin.v1.CreateOrderExecutionResponse,
  /**
   * @param {!proto.webapigateway.admin.v1.OrderExecution} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.webapigateway.admin.v1.CreateOrderExecutionResponse.deserializeBinary);
  /**
   * @param {!proto.webapigateway.admin.v1.OrderExecution} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @param {function(?grpc.web.Error, ?proto.webapigateway.admin.v1.CreateOrderExecutionResponse)}
   *     callback The callback function(error, response)
   * @return {!grpc.web.ClientReadableStream<!proto.webapigateway.admin.v1.CreateOrderExecutionResponse>|undefined}
   *     The XHR Node Readable Stream
   */

  proto.webapigateway.admin.v1.TradingInfoAPIClient.prototype.createOrderExecution = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + '/webapigateway.admin.v1.TradingInfoAPI/CreateOrderExecution', request, metadata || {}, methodDescriptor_TradingInfoAPI_CreateOrderExecution, callback);
  };
  /**
   * @param {!proto.webapigateway.admin.v1.OrderExecution} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @return {!Promise<!proto.webapigateway.admin.v1.CreateOrderExecutionResponse>}
   *     Promise that resolves to the response
   */


  proto.webapigateway.admin.v1.TradingInfoAPIPromiseClient.prototype.createOrderExecution = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + '/webapigateway.admin.v1.TradingInfoAPI/CreateOrderExecution', request, metadata || {}, methodDescriptor_TradingInfoAPI_CreateOrderExecution);
  };

  module.exports = proto.webapigateway.admin.v1;
});