define("incubex/grpc_web/api/admin/v1/admin_pb", [], function () {
  "use strict";

  /* eslint-disable */
  // source: api/admin/v1/admin.proto

  /**
   * @fileoverview
   * @enhanceable
   * @suppress {messageConventions} JS Compiler reports an error if a variable or
   *     field starts with 'MSG_' and isn't a translatable message.
   * @public
   */
  // GENERATED CODE -- DO NOT EDIT!
  var jspb = require("google-protobuf");

  var goog = jspb;
  var global = Function('return this')();
  goog.exportSymbol('proto.admin.v1.AccountState', null, global);
  goog.exportSymbol('proto.admin.v1.Email', null, global);
  goog.exportSymbol('proto.admin.v1.Email.BodyAttributesCase', null, global);
  goog.exportSymbol('proto.admin.v1.EmailTemplate', null, global);
  goog.exportSymbol('proto.admin.v1.InstrumentIdentifier', null, global);
  goog.exportSymbol('proto.admin.v1.RiskAlertEmailBodyAttributes', null, global);
  goog.exportSymbol('proto.admin.v1.RiskAlertEmailType', null, global);
  goog.exportSymbol('proto.admin.v1.RiskLimit', null, global);
  goog.exportSymbol('proto.admin.v1.RiskPosition', null, global);
  goog.exportSymbol('proto.admin.v1.RiskPositionIdentifier', null, global);
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */

  proto.admin.v1.InstrumentIdentifier = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.InstrumentIdentifier, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.InstrumentIdentifier.displayName = 'proto.admin.v1.InstrumentIdentifier';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.RiskLimit = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.v1.RiskLimit.repeatedFields_, null);
  };

  goog.inherits(proto.admin.v1.RiskLimit, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.RiskLimit.displayName = 'proto.admin.v1.RiskLimit';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.RiskPositionIdentifier = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.RiskPositionIdentifier, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.RiskPositionIdentifier.displayName = 'proto.admin.v1.RiskPositionIdentifier';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.RiskPosition = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.RiskPosition, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.RiskPosition.displayName = 'proto.admin.v1.RiskPosition';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.Email = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, proto.admin.v1.Email.oneofGroups_);
  };

  goog.inherits(proto.admin.v1.Email, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.Email.displayName = 'proto.admin.v1.Email';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.RiskAlertEmailBodyAttributes, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.RiskAlertEmailBodyAttributes.displayName = 'proto.admin.v1.RiskAlertEmailBodyAttributes';
  }

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.InstrumentIdentifier.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.InstrumentIdentifier.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.InstrumentIdentifier} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.InstrumentIdentifier.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        marketSegmentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        securityId: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.InstrumentIdentifier}
   */


  proto.admin.v1.InstrumentIdentifier.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.InstrumentIdentifier();
    return proto.admin.v1.InstrumentIdentifier.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.InstrumentIdentifier} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.InstrumentIdentifier}
   */


  proto.admin.v1.InstrumentIdentifier.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {number} */
          reader.readUint64();
          msg.setMarketSegmentId(value);
          break;

        case 2:
          var value =
          /** @type {number} */
          reader.readUint64();
          msg.setSecurityId(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.InstrumentIdentifier.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.InstrumentIdentifier.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.InstrumentIdentifier} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.InstrumentIdentifier.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMarketSegmentId();

    if (f !== 0) {
      writer.writeUint64(1, f);
    }

    f = message.getSecurityId();

    if (f !== 0) {
      writer.writeUint64(2, f);
    }
  };
  /**
   * optional uint64 market_segment_id = 1;
   * @return {number}
   */


  proto.admin.v1.InstrumentIdentifier.prototype.getMarketSegmentId = function () {
    return (
      /** @type {number} */
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };
  /**
   * @param {number} value
   * @return {!proto.admin.v1.InstrumentIdentifier} returns this
   */


  proto.admin.v1.InstrumentIdentifier.prototype.setMarketSegmentId = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
  };
  /**
   * optional uint64 security_id = 2;
   * @return {number}
   */


  proto.admin.v1.InstrumentIdentifier.prototype.getSecurityId = function () {
    return (
      /** @type {number} */
      jspb.Message.getFieldWithDefault(this, 2, 0)
    );
  };
  /**
   * @param {number} value
   * @return {!proto.admin.v1.InstrumentIdentifier} returns this
   */


  proto.admin.v1.InstrumentIdentifier.prototype.setSecurityId = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
  };
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */


  proto.admin.v1.RiskLimit.repeatedFields_ = [13];

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.RiskLimit.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.RiskLimit.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.RiskLimit} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.RiskLimit.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        marketSegmentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        riskLimitUniqueIdentifier: jspb.Message.getFieldWithDefault(msg, 2, 0),
        bidSizeLimit: jspb.Message.getFieldWithDefault(msg, 4, ""),
        askSizeLimit: jspb.Message.getFieldWithDefault(msg, 5, ""),
        spreadsBidSizeLimit: jspb.Message.getFieldWithDefault(msg, 7, ""),
        spreadsAskSizeLimit: jspb.Message.getFieldWithDefault(msg, 8, ""),
        shortPositionLimit: jspb.Message.getFieldWithDefault(msg, 9, ""),
        longPositionLimit: jspb.Message.getFieldWithDefault(msg, 10, ""),
        positionAlertThreshold1Percentage: jspb.Message.getFieldWithDefault(msg, 11, ""),
        positionAlertThreshold2Percentage: jspb.Message.getFieldWithDefault(msg, 12, ""),
        additionalMarketSegmentIdsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.RiskLimit}
   */


  proto.admin.v1.RiskLimit.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.RiskLimit();
    return proto.admin.v1.RiskLimit.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.RiskLimit} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.RiskLimit}
   */


  proto.admin.v1.RiskLimit.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {number} */
          reader.readUint64();
          msg.setMarketSegmentId(value);
          break;

        case 2:
          var value =
          /** @type {number} */
          reader.readInt64();
          msg.setRiskLimitUniqueIdentifier(value);
          break;

        case 4:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setBidSizeLimit(value);
          break;

        case 5:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setAskSizeLimit(value);
          break;

        case 7:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setSpreadsBidSizeLimit(value);
          break;

        case 8:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setSpreadsAskSizeLimit(value);
          break;

        case 9:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setShortPositionLimit(value);
          break;

        case 10:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLongPositionLimit(value);
          break;

        case 11:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setPositionAlertThreshold1Percentage(value);
          break;

        case 12:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setPositionAlertThreshold2Percentage(value);
          break;

        case 13:
          var value =
          /** @type {!Array<number>} */
          reader.readPackedUint64();
          msg.setAdditionalMarketSegmentIdsList(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.RiskLimit.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.RiskLimit.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.RiskLimit} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.RiskLimit.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMarketSegmentId();

    if (f !== 0) {
      writer.writeUint64(1, f);
    }

    f = message.getRiskLimitUniqueIdentifier();

    if (f !== 0) {
      writer.writeInt64(2, f);
    }

    f = message.getBidSizeLimit();

    if (f.length > 0) {
      writer.writeString(4, f);
    }

    f = message.getAskSizeLimit();

    if (f.length > 0) {
      writer.writeString(5, f);
    }

    f = message.getSpreadsBidSizeLimit();

    if (f.length > 0) {
      writer.writeString(7, f);
    }

    f = message.getSpreadsAskSizeLimit();

    if (f.length > 0) {
      writer.writeString(8, f);
    }

    f = message.getShortPositionLimit();

    if (f.length > 0) {
      writer.writeString(9, f);
    }

    f = message.getLongPositionLimit();

    if (f.length > 0) {
      writer.writeString(10, f);
    }

    f = message.getPositionAlertThreshold1Percentage();

    if (f.length > 0) {
      writer.writeString(11, f);
    }

    f = message.getPositionAlertThreshold2Percentage();

    if (f.length > 0) {
      writer.writeString(12, f);
    }

    f = message.getAdditionalMarketSegmentIdsList();

    if (f.length > 0) {
      writer.writePackedUint64(13, f);
    }
  };
  /**
   * optional uint64 market_segment_id = 1;
   * @return {number}
   */


  proto.admin.v1.RiskLimit.prototype.getMarketSegmentId = function () {
    return (
      /** @type {number} */
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };
  /**
   * @param {number} value
   * @return {!proto.admin.v1.RiskLimit} returns this
   */


  proto.admin.v1.RiskLimit.prototype.setMarketSegmentId = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
  };
  /**
   * optional int64 risk_limit_unique_identifier = 2;
   * @return {number}
   */


  proto.admin.v1.RiskLimit.prototype.getRiskLimitUniqueIdentifier = function () {
    return (
      /** @type {number} */
      jspb.Message.getFieldWithDefault(this, 2, 0)
    );
  };
  /**
   * @param {number} value
   * @return {!proto.admin.v1.RiskLimit} returns this
   */


  proto.admin.v1.RiskLimit.prototype.setRiskLimitUniqueIdentifier = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
  };
  /**
   * optional string bid_size_limit = 4;
   * @return {string}
   */


  proto.admin.v1.RiskLimit.prototype.getBidSizeLimit = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 4, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskLimit} returns this
   */


  proto.admin.v1.RiskLimit.prototype.setBidSizeLimit = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
  };
  /**
   * optional string ask_size_limit = 5;
   * @return {string}
   */


  proto.admin.v1.RiskLimit.prototype.getAskSizeLimit = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 5, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskLimit} returns this
   */


  proto.admin.v1.RiskLimit.prototype.setAskSizeLimit = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
  };
  /**
   * optional string spreads_bid_size_limit = 7;
   * @return {string}
   */


  proto.admin.v1.RiskLimit.prototype.getSpreadsBidSizeLimit = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 7, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskLimit} returns this
   */


  proto.admin.v1.RiskLimit.prototype.setSpreadsBidSizeLimit = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
  };
  /**
   * optional string spreads_ask_size_limit = 8;
   * @return {string}
   */


  proto.admin.v1.RiskLimit.prototype.getSpreadsAskSizeLimit = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 8, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskLimit} returns this
   */


  proto.admin.v1.RiskLimit.prototype.setSpreadsAskSizeLimit = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
  };
  /**
   * optional string short_position_limit = 9;
   * @return {string}
   */


  proto.admin.v1.RiskLimit.prototype.getShortPositionLimit = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 9, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskLimit} returns this
   */


  proto.admin.v1.RiskLimit.prototype.setShortPositionLimit = function (value) {
    return jspb.Message.setProto3StringField(this, 9, value);
  };
  /**
   * optional string long_position_limit = 10;
   * @return {string}
   */


  proto.admin.v1.RiskLimit.prototype.getLongPositionLimit = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 10, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskLimit} returns this
   */


  proto.admin.v1.RiskLimit.prototype.setLongPositionLimit = function (value) {
    return jspb.Message.setProto3StringField(this, 10, value);
  };
  /**
   * optional string position_alert_threshold1_percentage = 11;
   * @return {string}
   */


  proto.admin.v1.RiskLimit.prototype.getPositionAlertThreshold1Percentage = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 11, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskLimit} returns this
   */


  proto.admin.v1.RiskLimit.prototype.setPositionAlertThreshold1Percentage = function (value) {
    return jspb.Message.setProto3StringField(this, 11, value);
  };
  /**
   * optional string position_alert_threshold2_percentage = 12;
   * @return {string}
   */


  proto.admin.v1.RiskLimit.prototype.getPositionAlertThreshold2Percentage = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 12, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskLimit} returns this
   */


  proto.admin.v1.RiskLimit.prototype.setPositionAlertThreshold2Percentage = function (value) {
    return jspb.Message.setProto3StringField(this, 12, value);
  };
  /**
   * repeated uint64 additional_market_segment_ids = 13;
   * @return {!Array<number>}
   */


  proto.admin.v1.RiskLimit.prototype.getAdditionalMarketSegmentIdsList = function () {
    return (
      /** @type {!Array<number>} */
      jspb.Message.getRepeatedField(this, 13)
    );
  };
  /**
   * @param {!Array<number>} value
   * @return {!proto.admin.v1.RiskLimit} returns this
   */


  proto.admin.v1.RiskLimit.prototype.setAdditionalMarketSegmentIdsList = function (value) {
    return jspb.Message.setField(this, 13, value || []);
  };
  /**
   * @param {number} value
   * @param {number=} opt_index
   * @return {!proto.admin.v1.RiskLimit} returns this
   */


  proto.admin.v1.RiskLimit.prototype.addAdditionalMarketSegmentIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
  };
  /**
   * Clears the list making it empty but non-null.
   * @return {!proto.admin.v1.RiskLimit} returns this
   */


  proto.admin.v1.RiskLimit.prototype.clearAdditionalMarketSegmentIdsList = function () {
    return this.setAdditionalMarketSegmentIdsList([]);
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.RiskPositionIdentifier.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.RiskPositionIdentifier.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.RiskPositionIdentifier} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.RiskPositionIdentifier.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        marketSegmentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        securityId: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.RiskPositionIdentifier}
   */


  proto.admin.v1.RiskPositionIdentifier.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.RiskPositionIdentifier();
    return proto.admin.v1.RiskPositionIdentifier.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.RiskPositionIdentifier} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.RiskPositionIdentifier}
   */


  proto.admin.v1.RiskPositionIdentifier.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {number} */
          reader.readUint64();
          msg.setMarketSegmentId(value);
          break;

        case 2:
          var value =
          /** @type {number} */
          reader.readUint64();
          msg.setSecurityId(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.RiskPositionIdentifier.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.RiskPositionIdentifier.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.RiskPositionIdentifier} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.RiskPositionIdentifier.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMarketSegmentId();

    if (f !== 0) {
      writer.writeUint64(1, f);
    }

    f = message.getSecurityId();

    if (f !== 0) {
      writer.writeUint64(2, f);
    }
  };
  /**
   * optional uint64 market_segment_id = 1;
   * @return {number}
   */


  proto.admin.v1.RiskPositionIdentifier.prototype.getMarketSegmentId = function () {
    return (
      /** @type {number} */
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };
  /**
   * @param {number} value
   * @return {!proto.admin.v1.RiskPositionIdentifier} returns this
   */


  proto.admin.v1.RiskPositionIdentifier.prototype.setMarketSegmentId = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
  };
  /**
   * optional uint64 security_id = 2;
   * @return {number}
   */


  proto.admin.v1.RiskPositionIdentifier.prototype.getSecurityId = function () {
    return (
      /** @type {number} */
      jspb.Message.getFieldWithDefault(this, 2, 0)
    );
  };
  /**
   * @param {number} value
   * @return {!proto.admin.v1.RiskPositionIdentifier} returns this
   */


  proto.admin.v1.RiskPositionIdentifier.prototype.setSecurityId = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.RiskPosition.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.RiskPosition.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.RiskPosition} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.RiskPosition.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        id: (f = msg.getId()) && proto.admin.v1.RiskPositionIdentifier.toObject(includeInstance, f),
        actualPosition: jspb.Message.getFieldWithDefault(msg, 2, ""),
        shortPosition: jspb.Message.getFieldWithDefault(msg, 3, ""),
        longPosition: jspb.Message.getFieldWithDefault(msg, 4, ""),
        shortWorkingOrderCount: jspb.Message.getFieldWithDefault(msg, 5, ""),
        longWorkingOrderCount: jspb.Message.getFieldWithDefault(msg, 6, ""),
        shortRiskLimit: jspb.Message.getFieldWithDefault(msg, 7, ""),
        longRiskLimit: jspb.Message.getFieldWithDefault(msg, 8, ""),
        shortPositionRiskLimitPercentage: jspb.Message.getFieldWithDefault(msg, 9, ""),
        longPositionRiskLimitPercentage: jspb.Message.getFieldWithDefault(msg, 10, ""),
        alertThreshold1Percentage: jspb.Message.getFieldWithDefault(msg, 11, ""),
        alertThreshold2Percentage: jspb.Message.getFieldWithDefault(msg, 12, "")
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.RiskPosition}
   */


  proto.admin.v1.RiskPosition.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.RiskPosition();
    return proto.admin.v1.RiskPosition.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.RiskPosition} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.RiskPosition}
   */


  proto.admin.v1.RiskPosition.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value = new proto.admin.v1.RiskPositionIdentifier();
          reader.readMessage(value, proto.admin.v1.RiskPositionIdentifier.deserializeBinaryFromReader);
          msg.setId(value);
          break;

        case 2:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setActualPosition(value);
          break;

        case 3:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setShortPosition(value);
          break;

        case 4:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLongPosition(value);
          break;

        case 5:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setShortWorkingOrderCount(value);
          break;

        case 6:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLongWorkingOrderCount(value);
          break;

        case 7:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setShortRiskLimit(value);
          break;

        case 8:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLongRiskLimit(value);
          break;

        case 9:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setShortPositionRiskLimitPercentage(value);
          break;

        case 10:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLongPositionRiskLimitPercentage(value);
          break;

        case 11:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setAlertThreshold1Percentage(value);
          break;

        case 12:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setAlertThreshold2Percentage(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.RiskPosition.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.RiskPosition.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.RiskPosition} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.RiskPosition.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getId();

    if (f != null) {
      writer.writeMessage(1, f, proto.admin.v1.RiskPositionIdentifier.serializeBinaryToWriter);
    }

    f = message.getActualPosition();

    if (f.length > 0) {
      writer.writeString(2, f);
    }

    f = message.getShortPosition();

    if (f.length > 0) {
      writer.writeString(3, f);
    }

    f = message.getLongPosition();

    if (f.length > 0) {
      writer.writeString(4, f);
    }

    f = message.getShortWorkingOrderCount();

    if (f.length > 0) {
      writer.writeString(5, f);
    }

    f = message.getLongWorkingOrderCount();

    if (f.length > 0) {
      writer.writeString(6, f);
    }

    f = message.getShortRiskLimit();

    if (f.length > 0) {
      writer.writeString(7, f);
    }

    f = message.getLongRiskLimit();

    if (f.length > 0) {
      writer.writeString(8, f);
    }

    f = message.getShortPositionRiskLimitPercentage();

    if (f.length > 0) {
      writer.writeString(9, f);
    }

    f = message.getLongPositionRiskLimitPercentage();

    if (f.length > 0) {
      writer.writeString(10, f);
    }

    f = message.getAlertThreshold1Percentage();

    if (f.length > 0) {
      writer.writeString(11, f);
    }

    f = message.getAlertThreshold2Percentage();

    if (f.length > 0) {
      writer.writeString(12, f);
    }
  };
  /**
   * optional RiskPositionIdentifier id = 1;
   * @return {?proto.admin.v1.RiskPositionIdentifier}
   */


  proto.admin.v1.RiskPosition.prototype.getId = function () {
    return (
      /** @type{?proto.admin.v1.RiskPositionIdentifier} */
      jspb.Message.getWrapperField(this, proto.admin.v1.RiskPositionIdentifier, 1)
    );
  };
  /**
   * @param {?proto.admin.v1.RiskPositionIdentifier|undefined} value
   * @return {!proto.admin.v1.RiskPosition} returns this
  */


  proto.admin.v1.RiskPosition.prototype.setId = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };
  /**
   * Clears the message field making it undefined.
   * @return {!proto.admin.v1.RiskPosition} returns this
   */


  proto.admin.v1.RiskPosition.prototype.clearId = function () {
    return this.setId(undefined);
  };
  /**
   * Returns whether this field is set.
   * @return {boolean}
   */


  proto.admin.v1.RiskPosition.prototype.hasId = function () {
    return jspb.Message.getField(this, 1) != null;
  };
  /**
   * optional string actual_position = 2;
   * @return {string}
   */


  proto.admin.v1.RiskPosition.prototype.getActualPosition = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 2, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskPosition} returns this
   */


  proto.admin.v1.RiskPosition.prototype.setActualPosition = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };
  /**
   * optional string short_position = 3;
   * @return {string}
   */


  proto.admin.v1.RiskPosition.prototype.getShortPosition = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 3, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskPosition} returns this
   */


  proto.admin.v1.RiskPosition.prototype.setShortPosition = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
  };
  /**
   * optional string long_position = 4;
   * @return {string}
   */


  proto.admin.v1.RiskPosition.prototype.getLongPosition = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 4, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskPosition} returns this
   */


  proto.admin.v1.RiskPosition.prototype.setLongPosition = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
  };
  /**
   * optional string short_working_order_count = 5;
   * @return {string}
   */


  proto.admin.v1.RiskPosition.prototype.getShortWorkingOrderCount = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 5, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskPosition} returns this
   */


  proto.admin.v1.RiskPosition.prototype.setShortWorkingOrderCount = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
  };
  /**
   * optional string long_working_order_count = 6;
   * @return {string}
   */


  proto.admin.v1.RiskPosition.prototype.getLongWorkingOrderCount = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 6, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskPosition} returns this
   */


  proto.admin.v1.RiskPosition.prototype.setLongWorkingOrderCount = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
  };
  /**
   * optional string short_risk_limit = 7;
   * @return {string}
   */


  proto.admin.v1.RiskPosition.prototype.getShortRiskLimit = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 7, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskPosition} returns this
   */


  proto.admin.v1.RiskPosition.prototype.setShortRiskLimit = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
  };
  /**
   * optional string long_risk_limit = 8;
   * @return {string}
   */


  proto.admin.v1.RiskPosition.prototype.getLongRiskLimit = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 8, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskPosition} returns this
   */


  proto.admin.v1.RiskPosition.prototype.setLongRiskLimit = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
  };
  /**
   * optional string short_position_risk_limit_percentage = 9;
   * @return {string}
   */


  proto.admin.v1.RiskPosition.prototype.getShortPositionRiskLimitPercentage = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 9, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskPosition} returns this
   */


  proto.admin.v1.RiskPosition.prototype.setShortPositionRiskLimitPercentage = function (value) {
    return jspb.Message.setProto3StringField(this, 9, value);
  };
  /**
   * optional string long_position_risk_limit_percentage = 10;
   * @return {string}
   */


  proto.admin.v1.RiskPosition.prototype.getLongPositionRiskLimitPercentage = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 10, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskPosition} returns this
   */


  proto.admin.v1.RiskPosition.prototype.setLongPositionRiskLimitPercentage = function (value) {
    return jspb.Message.setProto3StringField(this, 10, value);
  };
  /**
   * optional string alert_threshold1_percentage = 11;
   * @return {string}
   */


  proto.admin.v1.RiskPosition.prototype.getAlertThreshold1Percentage = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 11, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskPosition} returns this
   */


  proto.admin.v1.RiskPosition.prototype.setAlertThreshold1Percentage = function (value) {
    return jspb.Message.setProto3StringField(this, 11, value);
  };
  /**
   * optional string alert_threshold2_percentage = 12;
   * @return {string}
   */


  proto.admin.v1.RiskPosition.prototype.getAlertThreshold2Percentage = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 12, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskPosition} returns this
   */


  proto.admin.v1.RiskPosition.prototype.setAlertThreshold2Percentage = function (value) {
    return jspb.Message.setProto3StringField(this, 12, value);
  };
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */


  proto.admin.v1.Email.oneofGroups_ = [[2]];
  /**
   * @enum {number}
   */

  proto.admin.v1.Email.BodyAttributesCase = {
    BODY_ATTRIBUTES_NOT_SET: 0,
    RISK_ALERT_EMAIL_BODY_ATTRIBUTES: 2
  };
  /**
   * @return {proto.admin.v1.Email.BodyAttributesCase}
   */

  proto.admin.v1.Email.prototype.getBodyAttributesCase = function () {
    return (
      /** @type {proto.admin.v1.Email.BodyAttributesCase} */
      jspb.Message.computeOneofCase(this, proto.admin.v1.Email.oneofGroups_[0])
    );
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.Email.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.Email.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.Email} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.Email.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        emailTemplate: jspb.Message.getFieldWithDefault(msg, 1, 0),
        riskAlertEmailBodyAttributes: (f = msg.getRiskAlertEmailBodyAttributes()) && proto.admin.v1.RiskAlertEmailBodyAttributes.toObject(includeInstance, f)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.Email}
   */


  proto.admin.v1.Email.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.Email();
    return proto.admin.v1.Email.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.Email} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.Email}
   */


  proto.admin.v1.Email.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {!proto.admin.v1.EmailTemplate} */
          reader.readEnum();
          msg.setEmailTemplate(value);
          break;

        case 2:
          var value = new proto.admin.v1.RiskAlertEmailBodyAttributes();
          reader.readMessage(value, proto.admin.v1.RiskAlertEmailBodyAttributes.deserializeBinaryFromReader);
          msg.setRiskAlertEmailBodyAttributes(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.Email.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.Email.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.Email} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.Email.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEmailTemplate();

    if (f !== 0.0) {
      writer.writeEnum(1, f);
    }

    f = message.getRiskAlertEmailBodyAttributes();

    if (f != null) {
      writer.writeMessage(2, f, proto.admin.v1.RiskAlertEmailBodyAttributes.serializeBinaryToWriter);
    }
  };
  /**
   * optional EmailTemplate email_template = 1;
   * @return {!proto.admin.v1.EmailTemplate}
   */


  proto.admin.v1.Email.prototype.getEmailTemplate = function () {
    return (
      /** @type {!proto.admin.v1.EmailTemplate} */
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };
  /**
   * @param {!proto.admin.v1.EmailTemplate} value
   * @return {!proto.admin.v1.Email} returns this
   */


  proto.admin.v1.Email.prototype.setEmailTemplate = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
  };
  /**
   * optional RiskAlertEmailBodyAttributes risk_alert_email_body_attributes = 2;
   * @return {?proto.admin.v1.RiskAlertEmailBodyAttributes}
   */


  proto.admin.v1.Email.prototype.getRiskAlertEmailBodyAttributes = function () {
    return (
      /** @type{?proto.admin.v1.RiskAlertEmailBodyAttributes} */
      jspb.Message.getWrapperField(this, proto.admin.v1.RiskAlertEmailBodyAttributes, 2)
    );
  };
  /**
   * @param {?proto.admin.v1.RiskAlertEmailBodyAttributes|undefined} value
   * @return {!proto.admin.v1.Email} returns this
  */


  proto.admin.v1.Email.prototype.setRiskAlertEmailBodyAttributes = function (value) {
    return jspb.Message.setOneofWrapperField(this, 2, proto.admin.v1.Email.oneofGroups_[0], value);
  };
  /**
   * Clears the message field making it undefined.
   * @return {!proto.admin.v1.Email} returns this
   */


  proto.admin.v1.Email.prototype.clearRiskAlertEmailBodyAttributes = function () {
    return this.setRiskAlertEmailBodyAttributes(undefined);
  };
  /**
   * Returns whether this field is set.
   * @return {boolean}
   */


  proto.admin.v1.Email.prototype.hasRiskAlertEmailBodyAttributes = function () {
    return jspb.Message.getField(this, 2) != null;
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.RiskAlertEmailBodyAttributes.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.RiskAlertEmailBodyAttributes.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.RiskAlertEmailBodyAttributes} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.RiskAlertEmailBodyAttributes.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        t7Account: jspb.Message.getFieldWithDefault(msg, 1, ""),
        marketSegmentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        riskAlertEmailType: jspb.Message.getFieldWithDefault(msg, 3, 0),
        shortPositionRiskLimitPercentage: jspb.Message.getFieldWithDefault(msg, 4, ""),
        longPositionRiskLimitPercentage: jspb.Message.getFieldWithDefault(msg, 5, "")
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.RiskAlertEmailBodyAttributes}
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.RiskAlertEmailBodyAttributes();
    return proto.admin.v1.RiskAlertEmailBodyAttributes.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.RiskAlertEmailBodyAttributes} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.RiskAlertEmailBodyAttributes}
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setT7Account(value);
          break;

        case 2:
          var value =
          /** @type {number} */
          reader.readUint64();
          msg.setMarketSegmentId(value);
          break;

        case 3:
          var value =
          /** @type {!proto.admin.v1.RiskAlertEmailType} */
          reader.readEnum();
          msg.setRiskAlertEmailType(value);
          break;

        case 4:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setShortPositionRiskLimitPercentage(value);
          break;

        case 5:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setLongPositionRiskLimitPercentage(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.RiskAlertEmailBodyAttributes.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.RiskAlertEmailBodyAttributes} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getT7Account();

    if (f.length > 0) {
      writer.writeString(1, f);
    }

    f = message.getMarketSegmentId();

    if (f !== 0) {
      writer.writeUint64(2, f);
    }

    f = message.getRiskAlertEmailType();

    if (f !== 0.0) {
      writer.writeEnum(3, f);
    }

    f = message.getShortPositionRiskLimitPercentage();

    if (f.length > 0) {
      writer.writeString(4, f);
    }

    f = message.getLongPositionRiskLimitPercentage();

    if (f.length > 0) {
      writer.writeString(5, f);
    }
  };
  /**
   * optional string t7_account = 1;
   * @return {string}
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.prototype.getT7Account = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 1, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskAlertEmailBodyAttributes} returns this
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.prototype.setT7Account = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };
  /**
   * optional uint64 market_segment_id = 2;
   * @return {number}
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.prototype.getMarketSegmentId = function () {
    return (
      /** @type {number} */
      jspb.Message.getFieldWithDefault(this, 2, 0)
    );
  };
  /**
   * @param {number} value
   * @return {!proto.admin.v1.RiskAlertEmailBodyAttributes} returns this
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.prototype.setMarketSegmentId = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
  };
  /**
   * optional RiskAlertEmailType risk_alert_email_type = 3;
   * @return {!proto.admin.v1.RiskAlertEmailType}
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.prototype.getRiskAlertEmailType = function () {
    return (
      /** @type {!proto.admin.v1.RiskAlertEmailType} */
      jspb.Message.getFieldWithDefault(this, 3, 0)
    );
  };
  /**
   * @param {!proto.admin.v1.RiskAlertEmailType} value
   * @return {!proto.admin.v1.RiskAlertEmailBodyAttributes} returns this
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.prototype.setRiskAlertEmailType = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
  };
  /**
   * optional string short_position_risk_limit_percentage = 4;
   * @return {string}
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.prototype.getShortPositionRiskLimitPercentage = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 4, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskAlertEmailBodyAttributes} returns this
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.prototype.setShortPositionRiskLimitPercentage = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
  };
  /**
   * optional string long_position_risk_limit_percentage = 5;
   * @return {string}
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.prototype.getLongPositionRiskLimitPercentage = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 5, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.RiskAlertEmailBodyAttributes} returns this
   */


  proto.admin.v1.RiskAlertEmailBodyAttributes.prototype.setLongPositionRiskLimitPercentage = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
  };
  /**
   * @enum {number}
   */


  proto.admin.v1.AccountState = {
    ACCOUNT_STATE_INVALID: 0,
    ACCOUNT_STATE_OPEN: 1,
    ACCOUNT_STATE_SUSPENDED: 2,
    ACCOUNT_STATE_CLOSED: 3
  };
  /**
   * @enum {number}
   */

  proto.admin.v1.EmailTemplate = {
    EMAIL_TEMPLATE_INVALID: 0,
    EMAIL_TEMPLATE_NONE: 1,
    EMAIL_TEMPLATE_RISK_ALERT: 2
  };
  /**
   * @enum {number}
   */

  proto.admin.v1.RiskAlertEmailType = {
    RISK_ALERT_EMAIL_TYPE_INVALID: 0,
    RISK_ALERT_EMAIL_TYPE_RISK_POSITION_BREACHED: 1
  };
  goog.object.extend(exports, proto.admin.v1);
});