define("incubex/components/notification/messages", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.messages}}
    <ul class="notification-messages-component" data-ui-component="notification-messages">
      {{#each this.messages as |message|}}
        <Notification::Message @message={{message}} />
      {{/each}}
    </ul>
  {{/if}}
  
  */
  {
    id: "6aV0LkBt",
    block: "{\"symbols\":[\"message\"],\"statements\":[[4,\"if\",[[23,0,[\"messages\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"notification-messages-component\"],[10,\"data-ui-component\",\"notification-messages\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"messages\"]]],null,{\"statements\":[[0,\"      \"],[5,\"notification/message\",[],[[\"@message\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/notification/messages.hbs"
    }
  });

  let NotificationMessagesComponent = (_class = (_temp = class NotificationMessagesComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notification", _descriptor, this);

      _initializerDefineProperty(this, "messages", _descriptor2, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "messages", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this.notification.messages;
    }
  })), _class);
  _exports.default = NotificationMessagesComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NotificationMessagesComponent);
});