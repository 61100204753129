define("incubex/grpc_web/api/admin/v1/email_admin_api_grpc_web_pb", [], function () {
  "use strict";

  /**
   * @fileoverview gRPC-Web generated client stub for admin.v1
   * @enhanceable
   * @public
   */
  // GENERATED CODE -- DO NOT EDIT!

  /* eslint-disable */
  // @ts-nocheck
  const grpc = {};
  grpc.web = require("grpc-web");

  var api_admin_v1_admin_pb = require("incubex/grpc_web/api/admin/v1/admin_pb.js");

  const proto = {};
  proto.admin = {};
  proto.admin.v1 = require("incubex/grpc_web/api/admin/v1/email_admin_api_pb.js");
  /**
   * @param {string} hostname
   * @param {?Object} credentials
   * @param {?Object} options
   * @constructor
   * @struct
   * @final
   */

  proto.admin.v1.EmailAdminAPIClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options['format'] = 'text';
    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */

    this.client_ = new grpc.web.GrpcWebClientBase(options);
    /**
     * @private @const {string} The hostname
     */

    this.hostname_ = hostname;
  };
  /**
   * @param {string} hostname
   * @param {?Object} credentials
   * @param {?Object} options
   * @constructor
   * @struct
   * @final
   */


  proto.admin.v1.EmailAdminAPIPromiseClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options['format'] = 'text';
    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */

    this.client_ = new grpc.web.GrpcWebClientBase(options);
    /**
     * @private @const {string} The hostname
     */

    this.hostname_ = hostname;
  };
  /**
   * @const
   * @type {!grpc.web.MethodDescriptor<
   *   !proto.admin.v1.SendEmailRequest,
   *   !proto.admin.v1.SendEmailResponse>}
   */


  const methodDescriptor_EmailAdminAPI_SendEmail = new grpc.web.MethodDescriptor('/admin.v1.EmailAdminAPI/SendEmail', grpc.web.MethodType.UNARY, proto.admin.v1.SendEmailRequest, proto.admin.v1.SendEmailResponse,
  /**
   * @param {!proto.admin.v1.SendEmailRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.admin.v1.SendEmailResponse.deserializeBinary);
  /**
   * @const
   * @type {!grpc.web.AbstractClientBase.MethodInfo<
   *   !proto.admin.v1.SendEmailRequest,
   *   !proto.admin.v1.SendEmailResponse>}
   */

  const methodInfo_EmailAdminAPI_SendEmail = new grpc.web.AbstractClientBase.MethodInfo(proto.admin.v1.SendEmailResponse,
  /**
   * @param {!proto.admin.v1.SendEmailRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.admin.v1.SendEmailResponse.deserializeBinary);
  /**
   * @param {!proto.admin.v1.SendEmailRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @param {function(?grpc.web.Error, ?proto.admin.v1.SendEmailResponse)}
   *     callback The callback function(error, response)
   * @return {!grpc.web.ClientReadableStream<!proto.admin.v1.SendEmailResponse>|undefined}
   *     The XHR Node Readable Stream
   */

  proto.admin.v1.EmailAdminAPIClient.prototype.sendEmail = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + '/admin.v1.EmailAdminAPI/SendEmail', request, metadata || {}, methodDescriptor_EmailAdminAPI_SendEmail, callback);
  };
  /**
   * @param {!proto.admin.v1.SendEmailRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @return {!Promise<!proto.admin.v1.SendEmailResponse>}
   *     Promise that resolves to the response
   */


  proto.admin.v1.EmailAdminAPIPromiseClient.prototype.sendEmail = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + '/admin.v1.EmailAdminAPI/SendEmail', request, metadata || {}, methodDescriptor_EmailAdminAPI_SendEmail);
  };

  module.exports = proto.admin.v1;
});