define("incubex/grpc_web/api/admin/v1/email_admin_api_pb", [], function () {
  "use strict";

  /* eslint-disable */
  // source: api/admin/v1/email_admin_api.proto

  /**
   * @fileoverview
   * @enhanceable
   * @suppress {messageConventions} JS Compiler reports an error if a variable or
   *     field starts with 'MSG_' and isn't a translatable message.
   * @public
   */
  // GENERATED CODE -- DO NOT EDIT!
  var jspb = require("google-protobuf");

  var goog = jspb;
  var global = Function('return this')();

  var api_admin_v1_admin_pb = require("incubex/grpc_web/api/admin/v1/admin_pb.js");

  goog.object.extend(proto, api_admin_v1_admin_pb);
  goog.exportSymbol('proto.admin.v1.SendEmailRequest', null, global);
  goog.exportSymbol('proto.admin.v1.SendEmailResponse', null, global);
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */

  proto.admin.v1.SendEmailRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.SendEmailRequest, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.SendEmailRequest.displayName = 'proto.admin.v1.SendEmailRequest';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.SendEmailResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.SendEmailResponse, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.SendEmailResponse.displayName = 'proto.admin.v1.SendEmailResponse';
  }

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.SendEmailRequest.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.SendEmailRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.SendEmailRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.SendEmailRequest.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        email: (f = msg.getEmail()) && api_admin_v1_admin_pb.Email.toObject(includeInstance, f)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.SendEmailRequest}
   */


  proto.admin.v1.SendEmailRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.SendEmailRequest();
    return proto.admin.v1.SendEmailRequest.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.SendEmailRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.SendEmailRequest}
   */


  proto.admin.v1.SendEmailRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value = new api_admin_v1_admin_pb.Email();
          reader.readMessage(value, api_admin_v1_admin_pb.Email.deserializeBinaryFromReader);
          msg.setEmail(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.SendEmailRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.SendEmailRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.SendEmailRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.SendEmailRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEmail();

    if (f != null) {
      writer.writeMessage(1, f, api_admin_v1_admin_pb.Email.serializeBinaryToWriter);
    }
  };
  /**
   * optional Email email = 1;
   * @return {?proto.admin.v1.Email}
   */


  proto.admin.v1.SendEmailRequest.prototype.getEmail = function () {
    return (
      /** @type{?proto.admin.v1.Email} */
      jspb.Message.getWrapperField(this, api_admin_v1_admin_pb.Email, 1)
    );
  };
  /**
   * @param {?proto.admin.v1.Email|undefined} value
   * @return {!proto.admin.v1.SendEmailRequest} returns this
  */


  proto.admin.v1.SendEmailRequest.prototype.setEmail = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };
  /**
   * Clears the message field making it undefined.
   * @return {!proto.admin.v1.SendEmailRequest} returns this
   */


  proto.admin.v1.SendEmailRequest.prototype.clearEmail = function () {
    return this.setEmail(undefined);
  };
  /**
   * Returns whether this field is set.
   * @return {boolean}
   */


  proto.admin.v1.SendEmailRequest.prototype.hasEmail = function () {
    return jspb.Message.getField(this, 1) != null;
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.SendEmailResponse.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.SendEmailResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.SendEmailResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.SendEmailResponse.toObject = function (includeInstance, msg) {
      var f,
          obj = {};

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.SendEmailResponse}
   */


  proto.admin.v1.SendEmailResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.SendEmailResponse();
    return proto.admin.v1.SendEmailResponse.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.SendEmailResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.SendEmailResponse}
   */


  proto.admin.v1.SendEmailResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.SendEmailResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.SendEmailResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.SendEmailResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.SendEmailResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
  };

  goog.object.extend(exports, proto.admin.v1);
});