define("incubex/services/time", ["exports", "moment", "incubex/types/url"], function (_exports, _moment, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TimeService = (_class = (_temp = class TimeService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "sessionApi", _descriptor2, this);

      _initializerDefineProperty(this, "now", _descriptor3, this);
    }

    get offset() {
      return _moment.default.tz((0, _moment.default)(), this.userZoneName).format('Z');
    }

    get timezone() {
      return _moment.default.tz((0, _moment.default)(), this.userZoneName).format('z');
    }

    get userZoneName() {
      if (this.currentUser.timezone && this.currentUser.timezone !== 'System') {
        return this.currentUser.timezone;
      } else {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    }

    convertTimeIntoUserFormat(time, nanoFormat = false, timeZone = true, showSecond = false) {
      if (time.toString().length > 18) nanoFormat = true;

      if (nanoFormat) {
        time = parseInt(time) / 1000000;
      } else {
        time = parseInt(time);
      }

      let timeForamt = showSecond ? 'MM/DD/YYYY hh:mm:ss A' : 'MM/DD/YYYY hh:mm A';

      if (timeZone) {
        timeForamt = showSecond ? 'MM/DD/YYYY hh:mm:ss A z' : 'MM/DD/YYYY hh:mm A';
      }

      return (0, _moment.default)(time).tz(this.userZoneName).format(timeForamt);
    }

    converToMMDDYY(date) {
      return (0, _moment.default)(date).format('MM/DD/YYYY');
    }

    count() {
      let now = (0, _moment.default)(new Date().toISOString());
      if (this._offset < 0) this.now = now.tz(this.userZoneName).subtract(Math.abs(this._offset), 'milliseconds').format('HH:mm:ss');else this.now = now.tz(this.userZoneName).add(this._offset, 'milliseconds').format('HH:mm:ss');
    }

    async start() {
      await this.getTimeFromServer();
      this._interval = setInterval(() => this.count(), 1000);
      this._offsetInterval = setInterval(() => this.getTimeFromServer(), 60000);
    }

    stop() {
      if (this._interval) {
        clearInterval(this._interval);
        this._interval = null;
        this._offsetInterval = null;
      }
    }

    async getTimeFromServer() {
      try {
        let time = await this.sessionApi.get(_url.default.TIME);
        this._offset = _moment.default.unix(time.time).valueOf() - (0, _moment.default)().valueOf();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("ServerTimeError!", e);
        this._offset = 0;
      }
    }

    convertTimetoEDT(dateTime) {
      return _moment.default.utc(dateTime).tz("America/New_York").format('MM/DD/YYYY hh:mm:ss A');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "now", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '00:00:00';
    }
  })), _class);
  _exports.default = TimeService;
});