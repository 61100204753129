define("incubex/grpc_web/api/t7/admin/v1/order_admin_api_grpc_web_pb", [], function () {
  "use strict";

  /**
   * @fileoverview gRPC-Web generated client stub for t7.admin.v1
   * @enhanceable
   * @public
   */
  // GENERATED CODE -- DO NOT EDIT!

  /* eslint-disable */
  // @ts-nocheck
  const grpc = {};
  grpc.web = require("grpc-web");
  const proto = {};
  proto.t7 = {};
  proto.t7.admin = {};
  proto.t7.admin.v1 = require("incubex/grpc_web/api/t7/admin/v1/order_admin_api_pb.js");
  /**
   * @param {string} hostname
   * @param {?Object} credentials
   * @param {?Object} options
   * @constructor
   * @struct
   * @final
   */

  proto.t7.admin.v1.OrderAdminAPIClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options['format'] = 'text';
    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */

    this.client_ = new grpc.web.GrpcWebClientBase(options);
    /**
     * @private @const {string} The hostname
     */

    this.hostname_ = hostname;
  };
  /**
   * @param {string} hostname
   * @param {?Object} credentials
   * @param {?Object} options
   * @constructor
   * @struct
   * @final
   */


  proto.t7.admin.v1.OrderAdminAPIPromiseClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options['format'] = 'text';
    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */

    this.client_ = new grpc.web.GrpcWebClientBase(options);
    /**
     * @private @const {string} The hostname
     */

    this.hostname_ = hostname;
  };
  /**
   * @const
   * @type {!grpc.web.MethodDescriptor<
   *   !proto.t7.admin.v1.CancelOrderRequest,
   *   !proto.t7.admin.v1.CancelOrderResponse>}
   */


  const methodDescriptor_OrderAdminAPI_CancelOrder = new grpc.web.MethodDescriptor('/t7.admin.v1.OrderAdminAPI/CancelOrder', grpc.web.MethodType.UNARY, proto.t7.admin.v1.CancelOrderRequest, proto.t7.admin.v1.CancelOrderResponse,
  /**
   * @param {!proto.t7.admin.v1.CancelOrderRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.t7.admin.v1.CancelOrderResponse.deserializeBinary);
  /**
   * @const
   * @type {!grpc.web.AbstractClientBase.MethodInfo<
   *   !proto.t7.admin.v1.CancelOrderRequest,
   *   !proto.t7.admin.v1.CancelOrderResponse>}
   */

  const methodInfo_OrderAdminAPI_CancelOrder = new grpc.web.AbstractClientBase.MethodInfo(proto.t7.admin.v1.CancelOrderResponse,
  /**
   * @param {!proto.t7.admin.v1.CancelOrderRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.t7.admin.v1.CancelOrderResponse.deserializeBinary);
  /**
   * @param {!proto.t7.admin.v1.CancelOrderRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @param {function(?grpc.web.Error, ?proto.t7.admin.v1.CancelOrderResponse)}
   *     callback The callback function(error, response)
   * @return {!grpc.web.ClientReadableStream<!proto.t7.admin.v1.CancelOrderResponse>|undefined}
   *     The XHR Node Readable Stream
   */

  proto.t7.admin.v1.OrderAdminAPIClient.prototype.cancelOrder = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + '/t7.admin.v1.OrderAdminAPI/CancelOrder', request, metadata || {}, methodDescriptor_OrderAdminAPI_CancelOrder, callback);
  };
  /**
   * @param {!proto.t7.admin.v1.CancelOrderRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @return {!Promise<!proto.t7.admin.v1.CancelOrderResponse>}
   *     Promise that resolves to the response
   */


  proto.t7.admin.v1.OrderAdminAPIPromiseClient.prototype.cancelOrder = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + '/t7.admin.v1.OrderAdminAPI/CancelOrder', request, metadata || {}, methodDescriptor_OrderAdminAPI_CancelOrder);
  };
  /**
   * @const
   * @type {!grpc.web.MethodDescriptor<
   *   !proto.t7.admin.v1.CancelAllOrdersRequest,
   *   !proto.t7.admin.v1.CancelAllOrdersResponse>}
   */


  const methodDescriptor_OrderAdminAPI_CancelAllOrders = new grpc.web.MethodDescriptor('/t7.admin.v1.OrderAdminAPI/CancelAllOrders', grpc.web.MethodType.UNARY, proto.t7.admin.v1.CancelAllOrdersRequest, proto.t7.admin.v1.CancelAllOrdersResponse,
  /**
   * @param {!proto.t7.admin.v1.CancelAllOrdersRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.t7.admin.v1.CancelAllOrdersResponse.deserializeBinary);
  /**
   * @const
   * @type {!grpc.web.AbstractClientBase.MethodInfo<
   *   !proto.t7.admin.v1.CancelAllOrdersRequest,
   *   !proto.t7.admin.v1.CancelAllOrdersResponse>}
   */

  const methodInfo_OrderAdminAPI_CancelAllOrders = new grpc.web.AbstractClientBase.MethodInfo(proto.t7.admin.v1.CancelAllOrdersResponse,
  /**
   * @param {!proto.t7.admin.v1.CancelAllOrdersRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.t7.admin.v1.CancelAllOrdersResponse.deserializeBinary);
  /**
   * @param {!proto.t7.admin.v1.CancelAllOrdersRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @param {function(?grpc.web.Error, ?proto.t7.admin.v1.CancelAllOrdersResponse)}
   *     callback The callback function(error, response)
   * @return {!grpc.web.ClientReadableStream<!proto.t7.admin.v1.CancelAllOrdersResponse>|undefined}
   *     The XHR Node Readable Stream
   */

  proto.t7.admin.v1.OrderAdminAPIClient.prototype.cancelAllOrders = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + '/t7.admin.v1.OrderAdminAPI/CancelAllOrders', request, metadata || {}, methodDescriptor_OrderAdminAPI_CancelAllOrders, callback);
  };
  /**
   * @param {!proto.t7.admin.v1.CancelAllOrdersRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @return {!Promise<!proto.t7.admin.v1.CancelAllOrdersResponse>}
   *     Promise that resolves to the response
   */


  proto.t7.admin.v1.OrderAdminAPIPromiseClient.prototype.cancelAllOrders = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + '/t7.admin.v1.OrderAdminAPI/CancelAllOrders', request, metadata || {}, methodDescriptor_OrderAdminAPI_CancelAllOrders);
  };
  /**
   * @const
   * @type {!grpc.web.MethodDescriptor<
   *   !proto.t7.admin.v1.UsersWorkingOrderCountRequest,
   *   !proto.t7.admin.v1.UsersWorkingOrderCountResponse>}
   */


  const methodDescriptor_OrderAdminAPI_GetUsersWorkingOrderCount = new grpc.web.MethodDescriptor('/t7.admin.v1.OrderAdminAPI/GetUsersWorkingOrderCount', grpc.web.MethodType.UNARY, proto.t7.admin.v1.UsersWorkingOrderCountRequest, proto.t7.admin.v1.UsersWorkingOrderCountResponse,
  /**
   * @param {!proto.t7.admin.v1.UsersWorkingOrderCountRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.t7.admin.v1.UsersWorkingOrderCountResponse.deserializeBinary);
  /**
   * @const
   * @type {!grpc.web.AbstractClientBase.MethodInfo<
   *   !proto.t7.admin.v1.UsersWorkingOrderCountRequest,
   *   !proto.t7.admin.v1.UsersWorkingOrderCountResponse>}
   */

  const methodInfo_OrderAdminAPI_GetUsersWorkingOrderCount = new grpc.web.AbstractClientBase.MethodInfo(proto.t7.admin.v1.UsersWorkingOrderCountResponse,
  /**
   * @param {!proto.t7.admin.v1.UsersWorkingOrderCountRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.t7.admin.v1.UsersWorkingOrderCountResponse.deserializeBinary);
  /**
   * @param {!proto.t7.admin.v1.UsersWorkingOrderCountRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @param {function(?grpc.web.Error, ?proto.t7.admin.v1.UsersWorkingOrderCountResponse)}
   *     callback The callback function(error, response)
   * @return {!grpc.web.ClientReadableStream<!proto.t7.admin.v1.UsersWorkingOrderCountResponse>|undefined}
   *     The XHR Node Readable Stream
   */

  proto.t7.admin.v1.OrderAdminAPIClient.prototype.getUsersWorkingOrderCount = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + '/t7.admin.v1.OrderAdminAPI/GetUsersWorkingOrderCount', request, metadata || {}, methodDescriptor_OrderAdminAPI_GetUsersWorkingOrderCount, callback);
  };
  /**
   * @param {!proto.t7.admin.v1.UsersWorkingOrderCountRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @return {!Promise<!proto.t7.admin.v1.UsersWorkingOrderCountResponse>}
   *     Promise that resolves to the response
   */


  proto.t7.admin.v1.OrderAdminAPIPromiseClient.prototype.getUsersWorkingOrderCount = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + '/t7.admin.v1.OrderAdminAPI/GetUsersWorkingOrderCount', request, metadata || {}, methodDescriptor_OrderAdminAPI_GetUsersWorkingOrderCount);
  };

  module.exports = proto.t7.admin.v1;
});