define("incubex/grpc_web/api/admin/v1/account_admin_api_pb", [], function () {
  "use strict";

  /* eslint-disable */
  // source: api/admin/v1/account_admin_api.proto

  /**
   * @fileoverview
   * @enhanceable
   * @suppress {messageConventions} JS Compiler reports an error if a variable or
   *     field starts with 'MSG_' and isn't a translatable message.
   * @public
   */
  // GENERATED CODE -- DO NOT EDIT!
  var jspb = require("google-protobuf");

  var goog = jspb;
  var global = Function('return this')();

  var api_admin_v1_admin_pb = require("incubex/grpc_web/api/admin/v1/admin_pb.js");

  goog.object.extend(proto, api_admin_v1_admin_pb);
  goog.exportSymbol('proto.admin.v1.GetAccountStateRequest', null, global);
  goog.exportSymbol('proto.admin.v1.GetAccountStateResponse', null, global);
  goog.exportSymbol('proto.admin.v1.SetAccountStateRequest', null, global);
  goog.exportSymbol('proto.admin.v1.SetAccountStateResponse', null, global);
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */

  proto.admin.v1.GetAccountStateRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.GetAccountStateRequest, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.GetAccountStateRequest.displayName = 'proto.admin.v1.GetAccountStateRequest';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.GetAccountStateResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.GetAccountStateResponse, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.GetAccountStateResponse.displayName = 'proto.admin.v1.GetAccountStateResponse';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.SetAccountStateRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.SetAccountStateRequest, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.SetAccountStateRequest.displayName = 'proto.admin.v1.SetAccountStateRequest';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.SetAccountStateResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.SetAccountStateResponse, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.SetAccountStateResponse.displayName = 'proto.admin.v1.SetAccountStateResponse';
  }

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.GetAccountStateRequest.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.GetAccountStateRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.GetAccountStateRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.GetAccountStateRequest.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        t7Account: jspb.Message.getFieldWithDefault(msg, 1, "")
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.GetAccountStateRequest}
   */


  proto.admin.v1.GetAccountStateRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.GetAccountStateRequest();
    return proto.admin.v1.GetAccountStateRequest.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.GetAccountStateRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.GetAccountStateRequest}
   */


  proto.admin.v1.GetAccountStateRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setT7Account(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.GetAccountStateRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.GetAccountStateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.GetAccountStateRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.GetAccountStateRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getT7Account();

    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };
  /**
   * optional string t7_account = 1;
   * @return {string}
   */


  proto.admin.v1.GetAccountStateRequest.prototype.getT7Account = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 1, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.GetAccountStateRequest} returns this
   */


  proto.admin.v1.GetAccountStateRequest.prototype.setT7Account = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.GetAccountStateResponse.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.GetAccountStateResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.GetAccountStateResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.GetAccountStateResponse.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        state: jspb.Message.getFieldWithDefault(msg, 1, 0)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.GetAccountStateResponse}
   */


  proto.admin.v1.GetAccountStateResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.GetAccountStateResponse();
    return proto.admin.v1.GetAccountStateResponse.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.GetAccountStateResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.GetAccountStateResponse}
   */


  proto.admin.v1.GetAccountStateResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {!proto.admin.v1.AccountState} */
          reader.readEnum();
          msg.setState(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.GetAccountStateResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.GetAccountStateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.GetAccountStateResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.GetAccountStateResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getState();

    if (f !== 0.0) {
      writer.writeEnum(1, f);
    }
  };
  /**
   * optional AccountState state = 1;
   * @return {!proto.admin.v1.AccountState}
   */


  proto.admin.v1.GetAccountStateResponse.prototype.getState = function () {
    return (
      /** @type {!proto.admin.v1.AccountState} */
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };
  /**
   * @param {!proto.admin.v1.AccountState} value
   * @return {!proto.admin.v1.GetAccountStateResponse} returns this
   */


  proto.admin.v1.GetAccountStateResponse.prototype.setState = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.SetAccountStateRequest.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.SetAccountStateRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.SetAccountStateRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.SetAccountStateRequest.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        t7Account: jspb.Message.getFieldWithDefault(msg, 1, ""),
        state: jspb.Message.getFieldWithDefault(msg, 2, 0)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.SetAccountStateRequest}
   */


  proto.admin.v1.SetAccountStateRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.SetAccountStateRequest();
    return proto.admin.v1.SetAccountStateRequest.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.SetAccountStateRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.SetAccountStateRequest}
   */


  proto.admin.v1.SetAccountStateRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setT7Account(value);
          break;

        case 2:
          var value =
          /** @type {!proto.admin.v1.AccountState} */
          reader.readEnum();
          msg.setState(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.SetAccountStateRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.SetAccountStateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.SetAccountStateRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.SetAccountStateRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getT7Account();

    if (f.length > 0) {
      writer.writeString(1, f);
    }

    f = message.getState();

    if (f !== 0.0) {
      writer.writeEnum(2, f);
    }
  };
  /**
   * optional string t7_account = 1;
   * @return {string}
   */


  proto.admin.v1.SetAccountStateRequest.prototype.getT7Account = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 1, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.SetAccountStateRequest} returns this
   */


  proto.admin.v1.SetAccountStateRequest.prototype.setT7Account = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };
  /**
   * optional AccountState state = 2;
   * @return {!proto.admin.v1.AccountState}
   */


  proto.admin.v1.SetAccountStateRequest.prototype.getState = function () {
    return (
      /** @type {!proto.admin.v1.AccountState} */
      jspb.Message.getFieldWithDefault(this, 2, 0)
    );
  };
  /**
   * @param {!proto.admin.v1.AccountState} value
   * @return {!proto.admin.v1.SetAccountStateRequest} returns this
   */


  proto.admin.v1.SetAccountStateRequest.prototype.setState = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.SetAccountStateResponse.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.SetAccountStateResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.SetAccountStateResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.SetAccountStateResponse.toObject = function (includeInstance, msg) {
      var f,
          obj = {};

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.SetAccountStateResponse}
   */


  proto.admin.v1.SetAccountStateResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.SetAccountStateResponse();
    return proto.admin.v1.SetAccountStateResponse.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.SetAccountStateResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.SetAccountStateResponse}
   */


  proto.admin.v1.SetAccountStateResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.SetAccountStateResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.SetAccountStateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.SetAccountStateResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.SetAccountStateResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
  };

  goog.object.extend(exports, proto.admin.v1);
});