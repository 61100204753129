define("incubex/grpc_web/api/admin/v1/risk_admin_api_pb", [], function () {
  "use strict";

  /* eslint-disable */
  // source: api/admin/v1/risk_admin_api.proto

  /**
   * @fileoverview
   * @enhanceable
   * @suppress {messageConventions} JS Compiler reports an error if a variable or
   *     field starts with 'MSG_' and isn't a translatable message.
   * @public
   */
  // GENERATED CODE -- DO NOT EDIT!
  var jspb = require("google-protobuf");

  var goog = jspb;
  var global = Function('return this')();

  var api_admin_v1_admin_pb = require("incubex/grpc_web/api/admin/v1/admin_pb.js");

  goog.object.extend(proto, api_admin_v1_admin_pb);
  goog.exportSymbol('proto.admin.v1.GetAccountRiskPositionsRequest', null, global);
  goog.exportSymbol('proto.admin.v1.GetAccountRiskPositionsResponse', null, global);
  goog.exportSymbol('proto.admin.v1.GetRiskLimitRequest', null, global);
  goog.exportSymbol('proto.admin.v1.GetRiskLimitResponse', null, global);
  goog.exportSymbol('proto.admin.v1.SetRiskLimitRequest', null, global);
  goog.exportSymbol('proto.admin.v1.SetRiskLimitResponse', null, global);
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */

  proto.admin.v1.GetAccountRiskPositionsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.GetAccountRiskPositionsRequest, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.GetAccountRiskPositionsRequest.displayName = 'proto.admin.v1.GetAccountRiskPositionsRequest';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.GetAccountRiskPositionsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.admin.v1.GetAccountRiskPositionsResponse.repeatedFields_, null);
  };

  goog.inherits(proto.admin.v1.GetAccountRiskPositionsResponse, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.GetAccountRiskPositionsResponse.displayName = 'proto.admin.v1.GetAccountRiskPositionsResponse';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.GetRiskLimitRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.GetRiskLimitRequest, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.GetRiskLimitRequest.displayName = 'proto.admin.v1.GetRiskLimitRequest';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.GetRiskLimitResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.GetRiskLimitResponse, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.GetRiskLimitResponse.displayName = 'proto.admin.v1.GetRiskLimitResponse';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.SetRiskLimitRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.SetRiskLimitRequest, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.SetRiskLimitRequest.displayName = 'proto.admin.v1.SetRiskLimitRequest';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.admin.v1.SetRiskLimitResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.admin.v1.SetRiskLimitResponse, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.admin.v1.SetRiskLimitResponse.displayName = 'proto.admin.v1.SetRiskLimitResponse';
  }

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.GetAccountRiskPositionsRequest.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.GetAccountRiskPositionsRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.GetAccountRiskPositionsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.GetAccountRiskPositionsRequest.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        t7Account: jspb.Message.getFieldWithDefault(msg, 1, ""),
        breachedOnly: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.GetAccountRiskPositionsRequest}
   */


  proto.admin.v1.GetAccountRiskPositionsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.GetAccountRiskPositionsRequest();
    return proto.admin.v1.GetAccountRiskPositionsRequest.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.GetAccountRiskPositionsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.GetAccountRiskPositionsRequest}
   */


  proto.admin.v1.GetAccountRiskPositionsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setT7Account(value);
          break;

        case 2:
          var value =
          /** @type {boolean} */
          reader.readBool();
          msg.setBreachedOnly(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.GetAccountRiskPositionsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.GetAccountRiskPositionsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.GetAccountRiskPositionsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.GetAccountRiskPositionsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getT7Account();

    if (f.length > 0) {
      writer.writeString(1, f);
    }

    f = message.getBreachedOnly();

    if (f) {
      writer.writeBool(2, f);
    }
  };
  /**
   * optional string t7_account = 1;
   * @return {string}
   */


  proto.admin.v1.GetAccountRiskPositionsRequest.prototype.getT7Account = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 1, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.GetAccountRiskPositionsRequest} returns this
   */


  proto.admin.v1.GetAccountRiskPositionsRequest.prototype.setT7Account = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };
  /**
   * optional bool breached_only = 2;
   * @return {boolean}
   */


  proto.admin.v1.GetAccountRiskPositionsRequest.prototype.getBreachedOnly = function () {
    return (
      /** @type {boolean} */
      jspb.Message.getBooleanFieldWithDefault(this, 2, false)
    );
  };
  /**
   * @param {boolean} value
   * @return {!proto.admin.v1.GetAccountRiskPositionsRequest} returns this
   */


  proto.admin.v1.GetAccountRiskPositionsRequest.prototype.setBreachedOnly = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
  };
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */


  proto.admin.v1.GetAccountRiskPositionsResponse.repeatedFields_ = [1];

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.GetAccountRiskPositionsResponse.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.GetAccountRiskPositionsResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.GetAccountRiskPositionsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.GetAccountRiskPositionsResponse.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        riskPositionsList: jspb.Message.toObjectList(msg.getRiskPositionsList(), api_admin_v1_admin_pb.RiskPosition.toObject, includeInstance)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.GetAccountRiskPositionsResponse}
   */


  proto.admin.v1.GetAccountRiskPositionsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.GetAccountRiskPositionsResponse();
    return proto.admin.v1.GetAccountRiskPositionsResponse.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.GetAccountRiskPositionsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.GetAccountRiskPositionsResponse}
   */


  proto.admin.v1.GetAccountRiskPositionsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value = new api_admin_v1_admin_pb.RiskPosition();
          reader.readMessage(value, api_admin_v1_admin_pb.RiskPosition.deserializeBinaryFromReader);
          msg.addRiskPositions(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.GetAccountRiskPositionsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.GetAccountRiskPositionsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.GetAccountRiskPositionsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.GetAccountRiskPositionsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRiskPositionsList();

    if (f.length > 0) {
      writer.writeRepeatedMessage(1, f, api_admin_v1_admin_pb.RiskPosition.serializeBinaryToWriter);
    }
  };
  /**
   * repeated RiskPosition risk_positions = 1;
   * @return {!Array<!proto.admin.v1.RiskPosition>}
   */


  proto.admin.v1.GetAccountRiskPositionsResponse.prototype.getRiskPositionsList = function () {
    return (
      /** @type{!Array<!proto.admin.v1.RiskPosition>} */
      jspb.Message.getRepeatedWrapperField(this, api_admin_v1_admin_pb.RiskPosition, 1)
    );
  };
  /**
   * @param {!Array<!proto.admin.v1.RiskPosition>} value
   * @return {!proto.admin.v1.GetAccountRiskPositionsResponse} returns this
  */


  proto.admin.v1.GetAccountRiskPositionsResponse.prototype.setRiskPositionsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };
  /**
   * @param {!proto.admin.v1.RiskPosition=} opt_value
   * @param {number=} opt_index
   * @return {!proto.admin.v1.RiskPosition}
   */


  proto.admin.v1.GetAccountRiskPositionsResponse.prototype.addRiskPositions = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.admin.v1.RiskPosition, opt_index);
  };
  /**
   * Clears the list making it empty but non-null.
   * @return {!proto.admin.v1.GetAccountRiskPositionsResponse} returns this
   */


  proto.admin.v1.GetAccountRiskPositionsResponse.prototype.clearRiskPositionsList = function () {
    return this.setRiskPositionsList([]);
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.GetRiskLimitRequest.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.GetRiskLimitRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.GetRiskLimitRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.GetRiskLimitRequest.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        t7Account: jspb.Message.getFieldWithDefault(msg, 1, ""),
        instrumentId: (f = msg.getInstrumentId()) && api_admin_v1_admin_pb.InstrumentIdentifier.toObject(includeInstance, f)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.GetRiskLimitRequest}
   */


  proto.admin.v1.GetRiskLimitRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.GetRiskLimitRequest();
    return proto.admin.v1.GetRiskLimitRequest.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.GetRiskLimitRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.GetRiskLimitRequest}
   */


  proto.admin.v1.GetRiskLimitRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setT7Account(value);
          break;

        case 2:
          var value = new api_admin_v1_admin_pb.InstrumentIdentifier();
          reader.readMessage(value, api_admin_v1_admin_pb.InstrumentIdentifier.deserializeBinaryFromReader);
          msg.setInstrumentId(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.GetRiskLimitRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.GetRiskLimitRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.GetRiskLimitRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.GetRiskLimitRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getT7Account();

    if (f.length > 0) {
      writer.writeString(1, f);
    }

    f = message.getInstrumentId();

    if (f != null) {
      writer.writeMessage(2, f, api_admin_v1_admin_pb.InstrumentIdentifier.serializeBinaryToWriter);
    }
  };
  /**
   * optional string t7_account = 1;
   * @return {string}
   */


  proto.admin.v1.GetRiskLimitRequest.prototype.getT7Account = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 1, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.GetRiskLimitRequest} returns this
   */


  proto.admin.v1.GetRiskLimitRequest.prototype.setT7Account = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };
  /**
   * optional InstrumentIdentifier instrument_id = 2;
   * @return {?proto.admin.v1.InstrumentIdentifier}
   */


  proto.admin.v1.GetRiskLimitRequest.prototype.getInstrumentId = function () {
    return (
      /** @type{?proto.admin.v1.InstrumentIdentifier} */
      jspb.Message.getWrapperField(this, api_admin_v1_admin_pb.InstrumentIdentifier, 2)
    );
  };
  /**
   * @param {?proto.admin.v1.InstrumentIdentifier|undefined} value
   * @return {!proto.admin.v1.GetRiskLimitRequest} returns this
  */


  proto.admin.v1.GetRiskLimitRequest.prototype.setInstrumentId = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };
  /**
   * Clears the message field making it undefined.
   * @return {!proto.admin.v1.GetRiskLimitRequest} returns this
   */


  proto.admin.v1.GetRiskLimitRequest.prototype.clearInstrumentId = function () {
    return this.setInstrumentId(undefined);
  };
  /**
   * Returns whether this field is set.
   * @return {boolean}
   */


  proto.admin.v1.GetRiskLimitRequest.prototype.hasInstrumentId = function () {
    return jspb.Message.getField(this, 2) != null;
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.GetRiskLimitResponse.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.GetRiskLimitResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.GetRiskLimitResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.GetRiskLimitResponse.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        riskLimit: (f = msg.getRiskLimit()) && api_admin_v1_admin_pb.RiskLimit.toObject(includeInstance, f)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.GetRiskLimitResponse}
   */


  proto.admin.v1.GetRiskLimitResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.GetRiskLimitResponse();
    return proto.admin.v1.GetRiskLimitResponse.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.GetRiskLimitResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.GetRiskLimitResponse}
   */


  proto.admin.v1.GetRiskLimitResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value = new api_admin_v1_admin_pb.RiskLimit();
          reader.readMessage(value, api_admin_v1_admin_pb.RiskLimit.deserializeBinaryFromReader);
          msg.setRiskLimit(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.GetRiskLimitResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.GetRiskLimitResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.GetRiskLimitResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.GetRiskLimitResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRiskLimit();

    if (f != null) {
      writer.writeMessage(1, f, api_admin_v1_admin_pb.RiskLimit.serializeBinaryToWriter);
    }
  };
  /**
   * optional RiskLimit risk_limit = 1;
   * @return {?proto.admin.v1.RiskLimit}
   */


  proto.admin.v1.GetRiskLimitResponse.prototype.getRiskLimit = function () {
    return (
      /** @type{?proto.admin.v1.RiskLimit} */
      jspb.Message.getWrapperField(this, api_admin_v1_admin_pb.RiskLimit, 1)
    );
  };
  /**
   * @param {?proto.admin.v1.RiskLimit|undefined} value
   * @return {!proto.admin.v1.GetRiskLimitResponse} returns this
  */


  proto.admin.v1.GetRiskLimitResponse.prototype.setRiskLimit = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };
  /**
   * Clears the message field making it undefined.
   * @return {!proto.admin.v1.GetRiskLimitResponse} returns this
   */


  proto.admin.v1.GetRiskLimitResponse.prototype.clearRiskLimit = function () {
    return this.setRiskLimit(undefined);
  };
  /**
   * Returns whether this field is set.
   * @return {boolean}
   */


  proto.admin.v1.GetRiskLimitResponse.prototype.hasRiskLimit = function () {
    return jspb.Message.getField(this, 1) != null;
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.SetRiskLimitRequest.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.SetRiskLimitRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.SetRiskLimitRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.SetRiskLimitRequest.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        t7Account: jspb.Message.getFieldWithDefault(msg, 1, ""),
        riskLimit: (f = msg.getRiskLimit()) && api_admin_v1_admin_pb.RiskLimit.toObject(includeInstance, f)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.SetRiskLimitRequest}
   */


  proto.admin.v1.SetRiskLimitRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.SetRiskLimitRequest();
    return proto.admin.v1.SetRiskLimitRequest.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.SetRiskLimitRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.SetRiskLimitRequest}
   */


  proto.admin.v1.SetRiskLimitRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setT7Account(value);
          break;

        case 2:
          var value = new api_admin_v1_admin_pb.RiskLimit();
          reader.readMessage(value, api_admin_v1_admin_pb.RiskLimit.deserializeBinaryFromReader);
          msg.setRiskLimit(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.SetRiskLimitRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.SetRiskLimitRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.SetRiskLimitRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.SetRiskLimitRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getT7Account();

    if (f.length > 0) {
      writer.writeString(1, f);
    }

    f = message.getRiskLimit();

    if (f != null) {
      writer.writeMessage(2, f, api_admin_v1_admin_pb.RiskLimit.serializeBinaryToWriter);
    }
  };
  /**
   * optional string t7_account = 1;
   * @return {string}
   */


  proto.admin.v1.SetRiskLimitRequest.prototype.getT7Account = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 1, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.admin.v1.SetRiskLimitRequest} returns this
   */


  proto.admin.v1.SetRiskLimitRequest.prototype.setT7Account = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };
  /**
   * optional RiskLimit risk_limit = 2;
   * @return {?proto.admin.v1.RiskLimit}
   */


  proto.admin.v1.SetRiskLimitRequest.prototype.getRiskLimit = function () {
    return (
      /** @type{?proto.admin.v1.RiskLimit} */
      jspb.Message.getWrapperField(this, api_admin_v1_admin_pb.RiskLimit, 2)
    );
  };
  /**
   * @param {?proto.admin.v1.RiskLimit|undefined} value
   * @return {!proto.admin.v1.SetRiskLimitRequest} returns this
  */


  proto.admin.v1.SetRiskLimitRequest.prototype.setRiskLimit = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };
  /**
   * Clears the message field making it undefined.
   * @return {!proto.admin.v1.SetRiskLimitRequest} returns this
   */


  proto.admin.v1.SetRiskLimitRequest.prototype.clearRiskLimit = function () {
    return this.setRiskLimit(undefined);
  };
  /**
   * Returns whether this field is set.
   * @return {boolean}
   */


  proto.admin.v1.SetRiskLimitRequest.prototype.hasRiskLimit = function () {
    return jspb.Message.getField(this, 2) != null;
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.admin.v1.SetRiskLimitResponse.prototype.toObject = function (opt_includeInstance) {
      return proto.admin.v1.SetRiskLimitResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.admin.v1.SetRiskLimitResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.admin.v1.SetRiskLimitResponse.toObject = function (includeInstance, msg) {
      var f,
          obj = {};

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.admin.v1.SetRiskLimitResponse}
   */


  proto.admin.v1.SetRiskLimitResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.admin.v1.SetRiskLimitResponse();
    return proto.admin.v1.SetRiskLimitResponse.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.admin.v1.SetRiskLimitResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.admin.v1.SetRiskLimitResponse}
   */


  proto.admin.v1.SetRiskLimitResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.admin.v1.SetRiskLimitResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.admin.v1.SetRiskLimitResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.admin.v1.SetRiskLimitResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.admin.v1.SetRiskLimitResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
  };

  goog.object.extend(exports, proto.admin.v1);
});